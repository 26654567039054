import { SET_AUTH_INFO } from '@/store/auth/actions.type';
import { LOGIN, USER, PASSWORD } from './actions.type';
import {
  SET_USER, SET_PASSWORD, LOADING, SET_ERROR,
} from './mutations.type';

import service from '../service/login-service';

export default {
  [LOGIN](context) {
    context.commit(LOADING, true);
    const user = {
      email: context.state.user.trim(),
      password: context.state.password.trim(),
    };
    return service.logar(user)
      .then((response) => {
        context.dispatch(SET_AUTH_INFO, response.data, { root: true });
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ERROR, error.response.data.message);
          context.commit(SET_PASSWORD, '');
          throw Error(error);
        }
        context.commit(SET_ERROR, 'Erro ao fazer login, tente novamente mais tarde');
        throw Error(error);
      }).finally(() => {
        context.commit(LOADING, false);
      });
  },
  [USER](context, user) {
    context.commit(SET_USER, user);
  },
  [PASSWORD](context, password) {
    context.commit(SET_PASSWORD, password);
  },
};
