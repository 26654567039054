import Vue from 'vue';
import axios from '@/modules/marketplace/axios-marketplace';
import { SurveysPlugin } from 'px-business-components';

const surveysBaseUrl = process.env.VUE_APP_API_URL_SURVEYS;

Vue.use(SurveysPlugin, {
  baseUrl: surveysBaseUrl,
  axios,
});
