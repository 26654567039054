<template>
  <div>
    <v-menu
      open-on-hover
      content-class="rounded-right menu-shadow"
      absolute
      :position-x="xValue"
      :position-y="yValue"
      v-model="menuOpened"
      :close-on-content-click="true"
      transition="slide-x-transition"
      :min-width="width"
      :max-width="width"
      offset-x
    >
      <template v-slot:activator="{ on }">
        <v-list-item v-on="{ ...on, mouseenter: onClickMenu }">
          <v-list-item-content>
            <b>
              Todos os departamentos
            </b>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-card tile min-height="529px">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4"
                   v-for="departamento in todosDepartamentos"
                   :key="departamento.id">
              <router-link :to="`/q/d/${departamento.id}`"
                           class="body-1 link-group text--primary link-group font-weight-bold">
                {{ departamento.descricao }}
              </router-link>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    todosDepartamentos: Array,
  },
  data: () => ({
    menuOpened: false,
    xValue: 0,
    yValue: 0,
  }),
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    width() {
      if (this.isSmall) {
        return '300px';
      }
      if (this.isSm) {
        return '650px';
      }
      return '800px';
    },
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    onClickMenu() {
      setTimeout(() => {
        const departamentosMenu = document.getElementById('menuDepartamentos');
        const menuPosition = departamentosMenu.getBoundingClientRect();
        this.xValue = menuPosition.left + menuPosition.width;
        this.yValue = menuPosition.top;
        this.menuOpened = true;
      });
    },
  },
};
</script>
