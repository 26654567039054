export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isSm() {
      return this.$vuetify.breakpoint.smOnly;
    },
  },
  methods: {
    getGridImg(normalImg, mobileImg) {
      if (this.isMobile) {
        return mobileImg;
      }
      return normalImg;
    },
  },
};
