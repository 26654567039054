<template>
  <div class="d-inline-block">
    <v-tooltip bottom v-if="score">
      <template v-slot:activator="{ on }">
        <span v-on="on">{{ score.score | number('0.0') }}</span>
      </template>
      <span>
        <div>- {{ score.score | number('0.0') }} de 1000</div>
        <div>- Com base nas últimas {{ score.cotacoes | number('0') }} cotações</div>
        <div>- Respondeu {{ score.scoreRespostas | number('0') }}% das solicitações</div>
        <div v-if="score.scoreVendas">
          - Vendeu {{ score.scoreVendas | number('0') }}% das solicitações respondidas
        </div>
        <div v-if="score.scoreNegociacoes">
          - Respondeu {{ score.scoreNegociacoes| number('0') }}% das negociações
        </div>
    </span>
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on }">
        <span v-on="on">N/A</span>
      </template>
      <span>
        Sem dados suficientes para calcular
    </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    score: Object,
  },
};
</script>
