import axios from 'axios';

const getUser = () => axios.get('/user');
const aceitarTermo = () => axios.put('/user/aceitar-termo', {});
const hasComprador = () => axios.get('/user/has-comprador-marketplace');

export default {
  getUser,
  aceitarTermo,
  hasComprador,
};
