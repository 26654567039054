<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-icon :color="color" v-on="on">{{ icon }}</v-icon>
    </template>
    <span>{{ description }}</span>
  </v-tooltip>
</template>

<script>
import { StatusNegociacao } from 'px-business-components';

const MAP_ICONS = {
  SOLICITADO: {
    icon: 'mdi-handshake',
    color: 'amber darken-1',
  },
  RESPONDIDO: {
    icon: 'mdi-handshake',
    color: 'blue lighten-1',
  },
};

export default {
  props: {
    statusNegociacao: String,
  },
  computed: {
    description() {
      if (!this.statusNegociacao) {
        return null;
      }
      return StatusNegociacao.keys[this.statusNegociacao].description;
    },
    color() {
      if (!this.statusNegociacao) {
        return null;
      }
      return MAP_ICONS[this.statusNegociacao].color;
    },
    icon() {
      if (!this.statusNegociacao) {
        return null;
      }
      return MAP_ICONS[this.statusNegociacao].icon;
    },
  },
};
</script>
