<template>
  <div>
    <v-menu
      open-on-hover
      v-model="menuOpened"
      :close-on-content-click="true"
      transition="scale-transition"
      min-width="250"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <a v-on="on" class="menu-link text--primary">
          <div>
            <span>
              <v-icon :class="iconClass">mdi-account-circle-outline</v-icon>
              <b v-if="!isMobile">{{ userName }}</b>
            </span>
            <v-icon v-if="!isMobile">mdi-chevron-down</v-icon>
          </div>
        </a>
      </template>
      <v-card v-if="loading" class="text-center">
        <v-card-text>
          <px-circular-loading/>
        </v-card-text>
      </v-card>
      <v-card v-if="!loading">
        <v-list dense class="pb-0 pt-0">
          <v-list-item link to="/compras">
            <v-list-item-icon>
              <v-icon>mdi-package-variant-closed</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Minhas compras
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-list-item link to="/q/f/favoritos">
            <v-list-item-icon>
              <v-icon>mdi-heart</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Meus favoritos
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-list-item link to="/indicadores">
            <v-list-item-icon>
              <v-icon>mdi-finance</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Indicadores
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-list-item link to="/minha-conta">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Minha conta
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-list-item link to="/minha-empresa">
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Minha empresa
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-list-item v-if="isLogged" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Sair
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <px-aceite-termos v-model="aceiteTermo" @update="onTermoAceito"/>
  </div>
</template>

<script>
import { first } from 'lodash';
import { LOGOUT } from '@/store/auth/actions.type';
import { CLEAR_USER } from '@/store/user/actions.type';
import gridMixin from '@/mixins/grid-mixin';
import { mapActions, mapGetters } from 'vuex';
import AceiteTermos from '@/components/user-info/AceiteTermos.vue';

export default {
  mixins: [gridMixin],
  data: () => ({
    menuOpened: false,
    aceiteTermo: false,
  }),
  computed: {
    ...mapGetters('user', ['user', 'loading']),
    ...mapGetters(['isLogged']),
    userName() {
      return this.user && first(this.user.nome.split(' '));
    },
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    iconClass() {
      return this.isSmall ? '' : 'x-large-icon';
    },
  },
  methods: {
    ...mapActions('user', {
      clearUser: CLEAR_USER,
    }),
    logout() {
      this.menu = false;
      this.$store.dispatch(LOGOUT);
      this.clearUser();
      window.location = `${process.env.VUE_APP_APP_URL}/home`;
    },
    onTermoAceito() {
      window.location.reload();
    },
    checkTermos() {
      if (this.$route.name === 'public.termos-uso') {
        return;
      }
      if (this.user && !this.user.termoAceito) {
        this.aceiteTermo = true;
      }
    },
  },
  watch: {
    '$route.name': function () {
      this.checkTermos();
    },
  },
  created() {
    this.checkTermos();
  },
  components: {
    pxAceiteTermos: AceiteTermos,
  },
};
</script>
