<template>
  <v-row class="mt-6" dense no-gutters v-if="loading">
    <!--      todo: gambi para criar skeleton bonito, rever no futuro-->
    <v-col cols="12" sm="6" md="4" lg="3" v-for="i in [1, 2, 3, 4]" :key="i">
      <v-skeleton-loader type="card, text@3" max-width="200px" :loading="loading">
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>
