<template>
  <v-menu
    offset-y
    :max-width="menuWidth"
    min-width="250"
    max-height="480"
    v-model="menuOpened"
    :close-on-content-click="true"
    content-class="menu-overflow"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-badge
        class="large-badge"
        color="primary"
        overlap
        bottom
      >
        <template v-slot:badge>
          <span>{{ itens.length }}</span>
        </template>
        <v-btn icon :class="`mr-3 ${iconBtn}`"
               v-on="on">
          <v-icon :class="iconClass">mdi-cart-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-card v-if="loading" class="text-center" style="overflow-y: hidden">
      <v-card-text>
        <px-circular-loading/>
      </v-card-text>
    </v-card>
    <v-card v-if="!isLogged"  class="text-center">
      <v-card-text>
        <px-go-to-login-inline>
          <template v-slot:activator="{ on }">
            Você não está logado.
            <div>
              <a v-on="on">Entre</a> para ver os itens do seu carrinho.
            </div>
          </template>
        </px-go-to-login-inline>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading && isLogged">
      <v-list class="list-overflow pt-3" max-height="380">
        <v-list-item v-if="!itens.length">
          <v-list-item-content align="center">
            <v-list-item-title>
              Não há produtos no carrinho
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-for="(item, index) in itens">
          <v-list-item three-line :key="item.id">
            <v-list-item-avatar class="square-avatar"
                                width="40" height="40">
              <px-oferta-foto max-height="40"
                              max-width="40"
                              class="d-flex align-center justify-center"
                              :foto="item.oferta.oferta.foto"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.oferta.oferta.titulo }}
                <px-show-variacoes-simple :variacoes="item.oferta.variacoes"/>
                <div>
                  <span class="font-weight-bold dense-title-1">
                    {{ precoAVista(item.oferta) | currency }}
                  </span>
                  <span class="body-2">&nbsp;á vista</span>
                </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.oferta.fornecedor.pessoa.nome }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="onRemove(item)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="showDivider(index)" :key="`div${item.id}`"/>
        </template>
      </v-list>
      <v-card-actions class="pa-5">
        <v-btn block link x-large
               class="normal-btn-text font-weight-bold rounded-card-small"
               color="primary"
               :to="`/carrinho`">
          <v-icon large class="mr-3">mdi-cart-outline</v-icon>Ir para carrinho
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_ITENS, REMOVE_ITEM } from '@/store/carrinho/actions.type';
import GtmService from '@/api/gtm-service';
import gridMixin from '@/mixins/grid-mixin';

export default {
  mixins: [gridMixin],
  data() {
    return {
      menuOpened: false,
    };
  },
  computed: {
    ...mapGetters('carrinho', ['itens', 'loading']),
    ...mapGetters(['isLogged']),
    menuWidth() {
      if (this.isMobile) {
        return '350';
      }
      return '450';
    },
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    iconClass() {
      return this.isSmall ? '' : 'x-large-icon';
    },
    iconBtn() {
      return this.isSmall ? '' : 'x-large-button';
    },
    showDivider() {
      return index => index !== (this.itens.length - 1);
    },
    precoAVista() {
      return oferta => (oferta.promocaoAtiva
        ? oferta.precoPromocionalAVista : oferta.precoAVista);
    },
  },
  methods: {
    ...mapActions('carrinho', {
      getItens: GET_ITENS,
      removeItem: REMOVE_ITEM,
    }),
    sendGAEvent(item) {
      return {
        currency: 'BRL',
        items: [{
          item_id: item.id,
          item_name: item.oferta.oferta.titulo,
          price: item.oferta.precoAVista,
          currency: 'BRL',
          quantity: item.quantidade,
        }],
        value: item.quantidade * item.oferta.precoAVista,
      };
    },
    onRemove(item) {
      this.removeItem({ id: item.id })
        .then(() => {
          GtmService.push('remove_from_cart', this.sendGAEvent(item));
        });
    },
  },
  created() {
    if (this.isLogged) {
      this.getItens();
    }
  },
};
</script>

<style scoped lang="scss">
@import './src/scss/variables';

.menu-overflow {
  overflow-y: hidden;
}

.list-overflow {
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary-text-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary-text-color;
}
</style>
