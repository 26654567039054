<template>
  <div class="ml-5">
    <v-divider v-if="hasOfertas" class="bigger-divider"/>
    <px-home-page-block class="mt-12"
                        :query="query"
                        :show-divider="false"
                        open-new-tab
                        class-title="headline font-weight-bold"
                        title="Produtos similares"
                        @onload="onLoadOfertas"/>
  </div>
</template>

<script>
import HomePageBlock from '../../home-page/HomePageBlock.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasOfertas: false,
    };
  },
  computed: {
    query() {
      return `categoria.id = ${this.oferta.categoria.id} and id != ${this.oferta.id} and disponivel is true order by random`;
    },
  },
  methods: {
    onLoadOfertas(ofertas) {
      if (ofertas && ofertas.content) {
        this.hasOfertas = Boolean(ofertas.content.length);
      }
    },
  },
  components: {
    pxHomePageBlock: HomePageBlock,
  },
};
</script>
