<template>
  <px-go-to-login-block>
    <template v-slot:activator="{ on }">
      <v-img
        class="pointer-cursor"
        height="350px"
        v-on="on"
        :src="thumbImage"
      >
      </v-img>
    </template>
  </px-go-to-login-block>
</template>

<script>
import thumbImage from '../../../../assets/historico/login-historico.png';

export default {
  data() {
    return {
      thumbImage,
    };
  },
};
</script>
