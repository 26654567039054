import axios from 'axios';

const BASE_URL = process.env.VUE_APP_MARKETPLACE_API_URL;

const addProduto = data => axios({
  url: '/carrinho',
  method: 'post',
  baseURL: BASE_URL,
  data,
});

const getOfertasFlat = () => axios({
  url: '/carrinho/ofertas',
  method: 'get',
  baseURL: BASE_URL,
});

const getCarrinho = () => axios({
  url: '/carrinho',
  method: 'get',
  baseURL: BASE_URL,
});

const removeOferta = idCarrinho => axios({
  url: `/carrinho/ofertas/${idCarrinho}`,
  method: 'delete',
  baseURL: BASE_URL,
});

const gerarCompra = (idCarrinho, data) => axios({
  url: `/carrinho/${idCarrinho}/finalizar`,
  method: 'post',
  baseURL: BASE_URL,
  data,
});

const updateOferta = (idOferta, data) => axios({
  url: `/carrinho/ofertas/${idOferta}`,
  method: 'put',
  baseURL: BASE_URL,
  data,
});

const cotarFrete = (idCarrinho, configFreteId) => axios({
  url: `/carrinho/${idCarrinho}/config-fretes/${configFreteId}/cotar`,
  method: 'get',
  baseURL: BASE_URL,
});

export default {
  addProduto,
  getOfertasFlat,
  removeOferta,
  getCarrinho,
  gerarCompra,
  updateOferta,
  cotarFrete,
};
