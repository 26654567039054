<template>
  <div>
    <a class="menu-link text--primary font-weight-bold"
       @click="onClickMenu">
      <v-icon>mdi-menu</v-icon>
      <span>
        Departamentos
      </span>
    </a>
    <v-navigation-drawer
      v-model="menuOpened"
      fixed
      app
    >
      <v-row class="ml-3">
        <v-col cols="12" class="font-weight-bold body-2">
          Departamentos
        </v-col>
      </v-row>
      <v-list
        nav
        dense
      >
        <v-list-item @mousedown.middle="goToOfertas(departamento, true)"
                     @click="goToOfertas(departamento)"
                     v-for="departamento in departamentosTodos" :key="departamento.id">
          <v-list-item-title>{{ departamento.descricao }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import MenuService from './menu-service';
import { goToOfertasDepartamento } from '../../services/route-oferta-service';

export default {
  props: {
    btnClass: String,
  },
  data: () => ({
    menuOpened: false,
    loading: false,
    departamentos: null,
    departamentosTodos: null,
  }),
  computed: {
  },
  methods: {
    goToOfertas(departamento, newTab = false) {
      goToOfertasDepartamento(departamento.id, newTab);
    },
    onClickMenu() {
      this.menuOpened = !this.menuOpened;
    },
    onClose() {
      this.menuOpened = false;
    },
    getDepartamentos() {
      this.loading = true;
      MenuService.getDepartamentos()
        .then(({ data }) => {
          this.departamentos = data;
          this.departamentosTodos = this.departamentos;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getDepartamentos();
  },
};
</script>

<style scoped lang="scss">
@import './src/scss/variables';

.rounded-menu {
  border-radius: $default-border-radius 0 $default-border-radius $default-border-radius !important;
}
</style>
