<template>
  <div class="mt-2 mb-3">
    Por:
    <a class="link-fornecedor"
       :title="oferta.fornecedor.pessoa.nome"
       @click.stop.prevent="onClickLinkFornecedor">
      {{ oferta.fornecedor.pessoa.nome | truncate(25) }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    oferta: Object,
  },
  methods: {
    onClickLinkFornecedor() {
      const routeParams = {
        name: 'public.fornecedor',
        params: {
          id: this.oferta.fornecedor.id,
        },
      };
      const route = this.$router.resolve(routeParams);
      window.open(route.href, '_blank');
    },
  },
};
</script>

<style scoped>
.link-fornecedor {
  text-decoration: underline;
}
</style>
