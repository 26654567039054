import getters from './getters';

const state = {
  isLogged: false,
  hasLoggedUser: false,
};

export default {
  namespaced: false,
  state,
  getters,
};
