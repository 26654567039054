<template>
  <px-lateral-dialog v-model="dialog"
                     persistent
                     width="350">
    <template v-slot:title>
      Acesso negado
    </template>
    <template>
      <p>Seu usuário não possui acesso como comprador</p>
      <div class="text-right">
        <v-btn color="primary" @click="logout">
          Sair
        </v-btn>
      </div>
    </template>
  </px-lateral-dialog>
</template>

<script>
import { LOGOUT } from '@/store/auth/actions.type';

export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    logout() {
      this.$store.dispatch(LOGOUT);
      this.$router.go('/login');
    },
  },
};
</script>
