<template>
  <v-slide-x-transition>
    <div class="card-options">
      <div v-if="showCompare">
        <px-oferta-comparacao-select :oferta-param="oferta">
          <template v-slot:add="{ on }">
            <v-tooltip top>
              <template v-slot:activator="{ on: onTooltip  }">
                <v-btn fab x-small
                       elevation="1"
                       color="primary"
                       @click.prevent
                       v-on="{ ...onTooltip, ...on }">
                  <v-icon>mdi-format-list-bulleted-square</v-icon>
                </v-btn>
              </template>
              <span>Adicionar à comparação</span>
            </v-tooltip>
          </template>
          <template v-slot:remove="{ on }">
            <v-tooltip top>
              <template v-slot:activator="{ on: onTooltip  }">
                <v-btn fab x-small
                       elevation="1"
                       color="primary"
                       @click.prevent
                       v-on="{ ...onTooltip, ...on }">
                  <v-icon>mdi-playlist-remove</v-icon>
                </v-btn>
              </template>
              <span>Remover da comparação</span>
            </v-tooltip>
          </template>
        </px-oferta-comparacao-select>
      </div>
      <div v-if="showCart" class="mt-2">
        <px-oferta-dialog :id-oferta="oferta.id">
          <template v-slot:trigger="{ on }">
            <v-tooltip top>
              <template v-slot:activator="{ on: onTooltip  }">
                <v-btn fab x-small
                       title="Adicionar ao carrinho"
                       elevation="1"
                       color="secondary"
                       @click.prevent
                       v-on="{ ...onTooltip, ...on }">
                  <v-icon>mdi-cart</v-icon>
                </v-btn>
              </template>
              <span>Adicionar ao carrinho</span>
            </v-tooltip>
          </template>
        </px-oferta-dialog>
      </div>
    </div>
  </v-slide-x-transition>
</template>

<script>
import PxOfertaComparacaoSelect from '@/modules/compare/PxOfertaComparacaoSelect.vue';

export default {
  props: {
    showCart: {
      type: Boolean,
      default: true,
    },
    showCompare: {
      type: Boolean,
      default: true,
    },
    oferta: {
      type: Object,
      required: true,
    },
  },
  components: {
    PxOfertaComparacaoSelect,
  },
};
</script>

<style scoped>
.card-options {
  width: fit-content;
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 999;
}
</style>
