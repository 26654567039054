<template>
  <div class="d-flex align-center">
    <template v-if="!isLogged">
      <v-icon :class="iconClass">mdi-account-circle-outline</v-icon>
      <div :class="`ml-1 mr-1 ${textClass}`">
        <px-go-to-login-inline>
          <template v-slot:activator="{ on }">
            <a v-on="on" class="black-link">Entre</a>
          </template>
        </px-go-to-login-inline>
        ou
        <br>
        <px-go-to-login-inline>
          <template v-slot:activator="{ on }">
            <a v-on="on" class="black-link">cadastre-se</a>
          </template>
        </px-go-to-login-inline>
      </div>
    </template>
    <template v-else>
      <px-user-account-menu class="mr-1"/>
    </template>
  </div>
</template>

<script>
import UserAccountMenu from '../user-account/UserAccountMenu.vue';

export default {
  props: {
    isLogged: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    iconClass() {
      return this.isSmall ? '' : 'x-large-icon';
    },
    textClass() {
      return this.isSmall ? 'caption' : '';
    },
  },
  components: {
    pxUserAccountMenu: UserAccountMenu,
  },
};
</script>

<style scoped>
.black-link {
  color: black;
  text-decoration: underline;
}
</style>
