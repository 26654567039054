<template>
  <v-col cols="12" sm="6">
    <div class="mb-3">
      <span class="body-1 font-weight-bold">{{ grade.descricaoExibicao }}</span>
    </div>
    <px-select-data :label="grade.descricaoExibicao"
                    class="required rounded-card-small"
                    item-text="descricao"
                    item-value="id"
                    v-model="localValue"
                    :error="error"
                    @blur="onBlur"
                    @change="onChange"
                    :items="items"/>
  </v-col>
</template>

<script>
import { filter, flatten } from 'lodash';

export default {
  props: {
    value: {
      type: Object,
    },
    error: {
      type: [Boolean, Object],
    },
    oferta: {
      type: Object,
      required: true,
    },
    hasMultipleGrades: {
      type: Boolean,
      required: false,
    },
    grade: {
      type: Object,
      required: true,
    },
    variacoesDisponiveis: {
      type: Array,
      required: true,
    },
    selecteds: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
    selected() {
      return this.selecteds
        .map(this.buildNormalizedVariacao)
        .reduce((prev, current) => ({ ...prev, ...current }));
    },
    items() {
      const selectedFillter = { ...this.selected };
      delete selectedFillter[this.grade.id];
      const disponiveis = filter(this.variacoesDisponiveis, selectedFillter);

      // remove variações inativas
      const variacoes = flatten(disponiveis
        .filter(d => d.ativo)
        .map(d => d.variacoes))
        .filter(v => v.grade.id === this.grade.id)
        .map(v => v.id);

      return this.grade.variacoes.filter(v => variacoes.includes(v.id));
    },
  },
  methods: {
    buildNormalizedVariacao(selected) {
      if (!selected) {
        return null;
      }
      const normalized = {};
      normalized[selected.grade.id] = selected.id;
      return normalized;
    },
    onChange(event) {
      this.$emit('change', event);
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
  },
};
</script>
