<template>
  <div>
    <router-link :to="`/q/g/${grupo.id}`"
                 class="body-1 link-group font-weight-bold text--primary">
      {{ grupo.descricao }}
    </router-link>
    <v-list dense>
      <v-list-item v-for="categoria in categorias"
                   :key="categoria.id">
        <router-link :to="`/q/c/${categoria.id}`" class="link-group text--primary">
          {{ categoria.descricao }}
        </router-link>
      </v-list-item>
      <v-list-item v-if="hasLimitCategorias">
        <router-link :to="`/q/g/${grupo.id}`" class="link-group black--text">
          Ver todas
        </router-link>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
const LIMIT_CATEGORIAS = 5;

export default {
  props: {
    grupo: Object,
  },
  computed: {
    hasLimitCategorias() {
      return this.grupo.categorias.length > LIMIT_CATEGORIAS;
    },
    categorias() {
      if (this.hasLimitCategorias) {
        return this.grupo.categorias.slice(0, LIMIT_CATEGORIAS);
      }
      return this.grupo.categorias;
    },
  },
};
</script>
