const updateMetaDescription = function (title) {
  const description = `${title} com os melhores preços para sua empresa na Proxpect! Clique e confira`;
  document.querySelector('meta[name="description"]')
    .setAttribute('content', description);
};

export default {
  inserted: (el, binding) => updateMetaDescription(binding.value || el.innerText),
  update: (el, binding) => updateMetaDescription(binding.value || el.innerText),
  componentUpdated: (el, binding) => updateMetaDescription(binding.value || el.innerText),
};
