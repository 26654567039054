import Vue from 'vue';
import Vuetify, {
  VSnackbar, VBtn, VIcon, VRow, VCol, VSpacer, VBreadcrumbs, VTextField,
  VCard, VCardText, VCardActions, VCardTitle, VCardSubtitle,
  VSelect, VDatePicker, VMenu, VProgressCircular, VDialog,
  VList, VListItem, VListItemAction, VListItemContent, VListItemGroup,
  VListItemSubtitle, VListItemTitle, VToolbarTitle, VToolbar,
  VChip, VChipGroup, VTooltip, VAutocomplete, VContainer, VContent, VBadge,
  VTimePicker, VTextarea, VTimeline, VTimelineItem, VRating, VDivider,
  VListItemIcon, VTabItem, VTabsItems, VTabs, VTab, VSubheader, VAvatar,
  VPagination, VBtnToggle,
} from 'vuetify/lib';
// import colors from 'vuetify/lib/util/colors';
import PixLogo from '../components/PixLogo.vue';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
    VRow,
    VCol,
    VSpacer,
    VBreadcrumbs,
    VTextField,
    VCard,
    VCardText,
    VCardActions,
    VCardTitle,
    VCardSubtitle,
    VSelect,
    VDatePicker,
    VMenu,
    VProgressCircular,
    VDialog,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemGroup,
    VListItemSubtitle,
    VListItemTitle,
    VToolbarTitle,
    VToolbar,
    VChip,
    VChipGroup,
    VTooltip,
    VAutocomplete,
    VContainer,
    VContent,
    VBadge,
    VTimePicker,
    VTextarea,
    VTimeline,
    VTimelineItem,
    VRating,
    VDivider,
    VListItemIcon,
    VTabItem,
    VTabsItems,
    VTabs,
    VTab,
    VSubheader,
    VAvatar,
    VPagination,
    VBtnToggle,
  },
});

const PRIMARY_COLOR = '#305CA5';
const SECONDARY_COLOR = '#EF4F1F';

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: PRIMARY_COLOR,
        secondary: SECONDARY_COLOR,
        accent: SECONDARY_COLOR,
      },
    },
  },
  icons: {
    values: {
      customPix: {
        component: PixLogo,
      },
    },
  },
});

const OPTIONS = {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  DEFAULT_IMAGE_GRADIENT: 'rgba(48,92,165,.45), rgba(48,92,165,.4)',
  SECONDARY_IMAGE_GRADIENT: 'rgba(239, 79, 31, .45), rgba(239, 79, 31, .4)',
};

Vue.$pxMarketplace = OPTIONS;
Vue.prototype.$pxMarketplace = OPTIONS;
