<template>
  <v-card outlined class="mt-12 ml-5 rounded-card">
    <v-card-text class="text--primary">
      <div class="d-flex flex-column align-center">
    <div class="body-1 font-weight-bold-2">
      <v-icon left color="success">mdi-check</v-icon>
      Alerta de preço ativo
    </div>
    <div class="body-2 mt-3 text-center">
      O alerta de preço está ativo para o valor:
    </div>
    <div class="mt-8 font-weight-bold headline">
      {{ alerta.valor | currency }}
    </div>
    <div class="mt-8">
      <v-btn large
             color="primary"
             class="normal-btn-text rounded-tag-small elevation-0"
             @click="onClickUpdate">
        <v-icon left>mdi-bell-outline</v-icon>
        Ajustar contraproposta
      </v-btn>
    </div>
  </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    alerta: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClickUpdate() {
      this.$emit('update');
    },
  },
};
</script>
