<template>
  <div class="d-flex flex-column justify-center oferta-preco-container mb-10 mt-3">
    <template v-if="precoAVista">
      <div>
      <span class="text-line-through text--secondary" v-if="oferta.promocaoAtiva">
        De {{ oferta.precoAVista | currency }}
      </span>
      </div>
      <div class="text--primary">
      <span class="title font-weight-bold">
        por {{ precoAVista | currency }}
      </span>
        <div class="d-inline-block">
          &nbsp;à vista
        </div>
      </div>
      <div class="text--secondary">
        {{ precoAPrazo | currency }} a prazo
      </div>
    </template>
    <template v-else>
      <div class="text--primary title font-weight-bold">
        Consultar preço
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    oferta: [Object],
  },
  data() {
    return {};
  },
  computed: {
    precoAVista() {
      return this.oferta.promocaoAtiva
        ? this.oferta.precoPromocionalAVista : this.oferta.precoAVista;
    },
    precoAPrazo() {
      return this.oferta.promocaoAtiva
        ? this.oferta.precoPromocionalAPrazo : this.oferta.precoAPrazo;
    },
  },
  methods: {},
};
</script>

<style scoped>
.oferta-preco-container {
  min-height: 76px;
}
</style>
