<template>
  <div>
    <v-container fluid fill-height class="login-container">
      <v-row class="body-2 mb-3">
        <v-col cols="12">
          <router-link to="/home">
            Home
          </router-link>
          >
          <span>
            Área de login
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span class="display-1 text--primary--color font-weight-bold">
            Identificação
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          Novo por aqui? Quer acessar mais informações?
          <div>Entre ou cadastra-se!</div>
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col cols="12" offset-sm="2" sm="3">
          <div class="headline text--primary--color font-weight-bold mb-10">
            Já tenho conta
          </div>
          <div class="error--text mt-2"
               v-if="error">
            {{error}}
          </div>
          <v-form
            class="login-content"
            ref="form"
          >
            <v-text-field
              solo
              :disabled="loading"
              v-model="user"
              :error="$v.user.$error"
              @blur="$v.user.$touch()"
              class="rounded-card-small mb-3"
              label="E-mail"
              required
            />
            <v-text-field
              solo
              hide-details
              v-model="password"
              label="Senha"
              class="rounded-card-small"
              :type="passwordType"
              :append-icon="passwordIcon"
              :disabled="loading"
              :error="$v.password.$error"
              @blur="$v.password.$touch()"
              @click:append="onClickShowPassword()"
              v-on:keyup.enter="doLogin"
              required
            />
            <v-row dense no-gutters class="mt-2 text-right">
              <v-col cols="12">
                <px-dialog-esqueceu-senha />
              </v-col>
            </v-row>
          </v-form>
          <div slot="actions" class="mt-10">
            <v-btn block
                   large
                   class="rounded-card-small normal-btn-text"
                   color="primary"
                   :disabled="$v.$invalid"
                   :loading="loading"
                   @click="doLogin">
              Continuar
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown"
               cols="1" class="login-divider"></v-col>
        <v-col cols="12" sm="3" offset-sm="1">
          <px-cadastro-comprador/>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="12" class="body-2 text-center">
          Em caso de dúvidas, entre em contato pelo e-mail contato@proxpect.com.br
          ou via
          <a target="_blank"
            href="https://api.whatsapp.com/send?phone=554891075464&text=Ol%C3%A1!%20Preciso%20de%20ajuda%20com%20o%20Marketplace%20Proxpect!">
            Whatsapp
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import EventService from '@/events';
import DialogEsqueceuSenha from '@/modules/login/DialogEsqueceuSenha.vue';
// eslint-disable-next-line import/no-cycle
import axios from '@/plugins/axios/axios-config';
import GtmService from '@/api/gtm-service';
import { LOGIN } from './store/actions.type';
import CadastroComprador from '../cadastro/CadastroComprador.vue';

export default {
  data() {
    return {
      showPassword: false,
      redirect: this.$route.query.redirect,
    };
  },
  computed: {
    ...mapGetters('login', {
      loading: 'loading',
      error: 'error',
    }),
    user: {
      get() {
        return this.$store.getters['login/user'];
      },
      set(user) {
        this.$store.dispatch('login/user', user);
      },
    },
    password: {
      get() {
        return this.$store.getters['login/password'];
      },
      set(password) {
        this.$store.dispatch('login/password', password);
      },
    },
    passwordType() {
      if (this.showPassword) {
        return 'text';
      }
      return 'password';
    },
    passwordIcon() {
      if (this.showPassword) {
        return 'mdi-eye-off';
      }
      return 'mdi-eye';
    },
  },
  methods: {
    ...mapActions('login', {
      logar: LOGIN,
    }),
    doLogin() {
      if (!this.user || !this.password) {
        return;
      }
      this.logar().then(() => {
        EventService.createLogin(axios, null);
        GtmService.push('login', { method: 'Proxpect' });
        if (this.redirect) {
          window.location = this.redirect;
          return;
        }
        this.$router.push({ name: 'public.home' });
      });
    },
    onClickShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
  validations: {
    user: {
      required,
    },
    password: {
      required,
    },
  },
  components: {
    pxCadastroComprador: CadastroComprador,
    pxDialogEsqueceuSenha: DialogEsqueceuSenha,
  },
};
</script>

<style scoped>
  .login-container {
    margin-top: 50px;
    max-width: 1280px;
    border-radius: 2px;
    background-color: white;
  }

  .login-content {
    padding-top: 15px;
  }

  .login-divider {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
</style>
