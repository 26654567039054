const URL_APP = process.env.VUE_APP_APP_URL;


export function buildLoginUrl(redirect = null) {
  const baseUrl = `${URL_APP}/login`;
  if (!redirect) {
    return `${baseUrl}?redirect=${encodeURIComponent(window.location.href)}`;
  }
  return `${baseUrl}?redirect=${redirect}`;
}

export function refreshPage() {
  window.location.reload();
}

export function getLoginUrl() {
  return `${URL_APP}/login`;
}
