import axios from 'axios';
import store from '@/store';
import { SET_AUTH_INFO, CLEAR_AUTH_INFO } from '@/store/auth/actions.type';
import { refreshPage } from '../../utils/login-utils';

const AUTH_IGNORE = ['AUTHENTICATION', 'BAD_CREDENTIALS'];
const INVALID_TOKEN = 'JWT_TOKEN_EXPIRED';
const AUTHORIZATION_HEADER = 'Authorization';
const ANONYMOUS = 'Bearer ANONYMOUS';

let isGettingToken = false;
let requestsToExecute = [];

const authInterceptor = (config) => {
  /* eslint-disable no-param-reassign */
  const { tokenHeader } = store.getters;

  if (!config.headers[AUTHORIZATION_HEADER] && tokenHeader) {
    config.headers[AUTHORIZATION_HEADER] = tokenHeader;
  }

  if (!config.headers[AUTHORIZATION_HEADER] && !tokenHeader) {
    config.headers[AUTHORIZATION_HEADER] = ANONYMOUS;
  }

  return config;
};

function isRefreshTokenRequest(url) {
  return url.endsWith('refresh');
}

function executeRequests(requests) {
  requests.forEach((req) => {
    /* eslint-disable no-param-reassign */
    delete req.request.headers[AUTHORIZATION_HEADER];
    axios(req.request)
      .then(resp => req.promise.resolve(resp));
  });
}

function onReceiveNewToken(response) {
  store.dispatch(SET_AUTH_INFO, response.data);
  return response;
}

function clearToken() {
  store.dispatch(CLEAR_AUTH_INFO);
}

function refreshPageOnFail() {
  refreshPage();
}

function refreshToken() {
  const { refreshTokenHeader } = store.getters;
  const config = {
    headers: {
      Authorization: refreshTokenHeader,
    },
  };

  return axios.get('/auth/token/refresh', config)
    .then(onReceiveNewToken);
}

function createDefer(obj = {}) { /* eslint-disable no-param-reassign */
  obj.promise = new Promise((resolve, reject) => {
    obj.resolve = resolve;
    obj.reject = reject;
  });
  return obj;
}


const responseAuthInterceptor = (config) => {
  const { response } = config;
  if (response.status !== 401 && response.status !== 400) {
    return Promise.reject(config);
  }

  if (!response.data || !response.data.reason) {
    return Promise.reject(config);
  }

  const { reason } = response.data;

  if (AUTH_IGNORE.includes(reason)
    || (reason === INVALID_TOKEN && isRefreshTokenRequest(response.config.url))) {
    isGettingToken = false;
    requestsToExecute = [];
    clearToken();
    refreshPageOnFail();
    return Promise.reject(config);
  }

  const defer = createDefer();
  requestsToExecute.push({
    request: response.config,
    promise: defer,
  });

  if (!isGettingToken) {
    refreshToken()
      .then(() => {
        executeRequests(requestsToExecute);
        requestsToExecute = [];
        isGettingToken = false;
      });

    isGettingToken = true;
  }

  return defer.promise;
};

export {
  authInterceptor,
  responseAuthInterceptor,
};
