<template>
  <a :href="link" TARGET="_blank">
    <slot>
      {{ text }}
    </slot>
  </a>
</template>

<script>
const WHATSAPP_URL = 'https://api.whatsapp.com/send?text=';

export default {
  props: {
    text: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  computed: {
    link() {
      return `${WHATSAPP_URL}${this.message}`;
    },
  },
};
</script>
