<template>
  <v-text-field
    id="search-text-field"
    class="search-text-box"
    :height="height"
    hide-details
    solo
    rounded
    light
    required
    v-model="searchText"
    label="O que você deseja comprar hoje?"
    autocomplete="off"
    @keyup="onChangeText"
    @focusin="onFocusInput"
    @keyup.enter="onSearch"
  >
    <template v-slot:append>
      <v-btn icon x-large @click="onSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <px-search-itens-menu v-model="menuOpenned"
                            :sugestoes="sugestoes"
                            :loading="loading"
                            @clickitem="onClickItem"/>
    </template>
  </v-text-field>
</template>

<script>
import { debounce } from 'vuetify/lib/util/helpers';
import { isEmpty } from 'lodash';
import GtmService from '@/api/gtm-service';
import { goToOfertasSearch } from '../../services/route-oferta-service';
import { TYPES } from '../ofertas/types';
import SearchItensMenu from './SearchItensMenu.vue';
import SugestoesService from './sugestao-service';

const QUANTIDADE_SUGESTOES = 5;
const ENTER = 'Enter';

export default {
  props: {
    height: {
      type: String,
      default: '65px',
    },
  },
  data() {
    return {
      searchText: null,
      menuOpenned: false,
      cancelOpen: false,
      loading: false,
      sugestoes: {
        content: [],
        total: 0,
      },
    };
  },
  computed: {
    isSearch() {
      return this.type === TYPES.search.id;
    },
    type() {
      return this.$route.params.type;
    },
    value() {
      return this.$route.params.value;
    },
    isTextEmpty() {
      return !this.searchText || this.searchText.length < 3;
    },
  },
  methods: {
    onClickItem() {
      this.searchText = null;
    },
    onSearch() {
      this.openCloseMenuLazy(false);
      document.getElementById('search-text-field').blur();
      goToOfertasSearch(this.searchText);
      this.cancelOpen = true;
    },
    onFocusInput() {
      if (!isEmpty(this.sugestoes.content) && !this.isTextEmpty) {
        this.openCloseMenuLazy();
      }
    },
    openCloseMenuLazy(state = true, time = 200) {
      setTimeout(() => {
        this.menuOpenned = state;
      }, time);
    },
    getSugestoes() {
      this.loading = true;
      GtmService.push('search', { search_term: this.searchText });
      SugestoesService.getSugestoes({
        limit: QUANTIDADE_SUGESTOES,
        q: this.searchText,
        query: 'disponivel is true',
        relevancia: true,
      })
        .then(({ data }) => {
          this.sugestoes = data;
          this.menuOpenned = this.cancelOpen ? false : !isEmpty(this.sugestoes.content);
          this.cancelOpen = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChangeText(event) {
      if (this.isTextEmpty || event.code === ENTER) {
        this.menuOpenned = false;
        return;
      }
      this.debounceSearch();
    },
  },
  watch: {
    isSearch(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (!newValue) {
        this.searchText = null;
      }
    },
  },
  created() {
    this.debounceSearch = debounce(this.getSugestoes, 1000);
    if (this.isSearch) {
      this.searchText = this.value;
    }
  },
  components: {
    pxSearchItensMenu: SearchItensMenu,
  },
};
</script>

<style scoped>
.search-text-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
