<template>
  <v-card :color="color" class="rounded-card">
    <v-card-text :class="`${paddings} white--text ${containerClass}`">
      <div class="d-flex flex-column flex-sm-row justify-space-around">
        <div class="title font-weight-bold pr-10 flex-column d-flex justify-center">
          <div>{{ text }}</div>
          <div v-if="subTitle" class="caption mt-2">
            {{ subTitle }}
          </div>
        </div>
        <div class="d-flex align-center">
          <template v-if="isLink">
            <v-btn outlined large
                   link to="/cadastro-news"
                   color="white"
                   class="rounded-tag-small normal-btn-text">
              {{ textBtn }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn outlined :x-large="xLarge"
                   @click="emitClick"
                   color="white"
                   class="rounded-tag-small normal-btn-text">
              {{ textBtn }}
            </v-btn>
          </template>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Que tal ficar por dentro de todas as nossas novidades e não perder nenhuma oferta?',
    },
    textBtn: {
      type: String,
      default: 'Cadastre-se',
    },
    color: {
      type: String,
      default: 'primary',
    },
    paddings: {
      type: String,
      default: 'pt-10 pb-10',
    },
    subTitle: {
      type: String,
    },
    isLink: {
      type: Boolean,
      default: true,
    },
    xLarge: {
      type: Boolean,
      default: true,
    },
    containerClassProp: {
      type: String,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    containerClass() {
      if (this.isMobile) {
        return '';
      }
      return this.containerClassProp || 'banner-cadastro-container';
    },
  },
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.banner-cadastro-container {
  padding-right: 200px !important;
  padding-left: 200px !important;
}
</style>
