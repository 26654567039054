import Main from '@/modules/main/Main.vue';
import Header from '@/modules/header/Header.vue';
import store from '@/store';
import Footer from '@/modules/footer/Footer.vue';

import { INITIALIZE_FROM_COOKIES_AC, SET_REDIRECT_ACT } from '@/store/auth/actions.type';
import { FETCH_USER } from '@/store/user/actions.type';

const beforeEnterAuth = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    if (store.getters.tokenFromStorage) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC);
      return store.dispatch(`user/${FETCH_USER}`)
        .then(() => next());
    }
    store.dispatch(SET_REDIRECT_ACT, to.fullPath);
    return next('/login');
  }
  return store.dispatch(`user/${FETCH_USER}`)
    .then(() => next());
};

export default {
  path: '/',
  name: 'main',
  redirect: { name: 'public.home' },
  components: {
    default: Main,
    header: Header,
    footer: Footer,
  },
  children: [
    {
      path: 'minha-conta',
      name: 'main.minha-conta',
      meta: {
        title: 'Minha conta | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "minha-conta" */ '@/modules/minha-conta/MinhaConta.vue'),
      },
    },
    {
      path: 'minha-empresa',
      name: 'main.minha-empresa',
      meta: {
        title: 'Minha empresa | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "minha-empresa" */ '@/modules/minha-empresa/MinhaEmpresa.vue'),
      },
    },
    {
      path: 'carrinho',
      name: 'main.carrinho',
      meta: {
        title: 'Carrinho de compras | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "carrinho" */ '@/modules/carrinho/Carrinho.vue'),
      },
    },
    {
      path: 'compras',
      name: 'main.compras',
      meta: {
        title: 'Minhas compras | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "compras" */ '@/modules/compras/Compras.vue'),
      },
    },
    {
      path: 'compras/:id',
      name: 'main.compra.detalhes',
      meta: {
        title: 'Minhas compras | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "compras-detalhes" */ '@/modules/compras/detalhes/Compra.vue'),
      },
    },
    {
      path: 'indicadores',
      name: 'main.indicadores',
      meta: {
        title: 'Indicadores | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "dashboard" */ '@/modules/indicadores/Dashboard.vue'),
      },
    },
    {
      path: 'welcome',
      name: 'main.welcome',
      meta: {
        title: 'Bem-vindo | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "welcome" */ '@/modules/welcome/Welcome.vue'),
      },
    },
  ],
  beforeEnter: beforeEnterAuth,
};
