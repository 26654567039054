<template>
  <v-edit-dialog
    large
    @save="save"
    @open="open"
    save-text="SALVAR"
    cancel-text="CANCELAR"
    :return-value.sync="editText">
    <px-situacao-cotacao-icon v-if="showSituacao" :situacao="cotacao.situacao"/>
    <slot name="before">
    </slot>
    <px-span-tooltip top :text="identificador" :tooltip="cotacao.identificador"/>
    <template v-slot:input>
      <px-edit-identificador v-model="editText"/>
    </template>
  </v-edit-dialog>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    cotacao: Object,
    saveFunction: {
      type: Function,
      default() {},
    },
    showSituacao: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editText: null,
    };
  },
  computed: {
    identificador() {
      return Vue.filter('truncate')(this.cotacao.identificador, 25);
    },
  },
  methods: {
    buildCotacao() {
      return {
        id: this.cotacao.id,
        identificador: this.editText,
      };
    },
    saveIdentifier() {
      this.saveFunction(this.cotacao.id, this.buildCotacao())
        .then(() => {
          this.$toast('Identificador atualizado', { color: 'success' });
          this.$emit('update');
        });
    },
    save() {
      this.saveIdentifier();
    },
    open() {
      this.editText = this.cotacao.identificador;
    },
  },
};
</script>
