<template>

  <v-container class="mkt-content-container d-flex justify-center mt-10">
    <div class="redefinir-senha-container">
      <form autocomplete="off">
        <!--  fake fields are a workaround for chrome/opera autofill getting the wrong fields -->
        <input id="password" style="display:none" type="password" name="fakepasswordremembered">
        <input id="matchPassword" style="display:none" type="password"
               name="fakepasswordremembered">
        <v-row class="body-1">
          <v-col cols="12">
            Insira a nova senha e a confirmação:
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="12">
            <v-text-field
              solo
              label="Senha"
              class="required rounded-card-small"
              minlength="8"
              maxlength="30"
              autocomplete="off"
              :append-icon="passwordIcon(this.showPassword)"
              :type="passwordType(this.showPassword)"
              :messages="passwordMessages"
              :error="$v.password.$error"
              @blur="$v.password.$touch()"
              v-model="password"
              @click:append="changeShowPassword('showPassword')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              solo
              hide-details
              label="Repetir senha"
              class="required rounded-card-small"
              minlength="8"
              maxlength="30"
              autocomplete="off"
              :append-icon="passwordIcon(this.showMatchPassword)"
              :type="passwordType(this.showMatchPassword)"
              :error="$v.matchPassword.$error"
              @blur="$v.matchPassword.$touch()"
              v-model="matchPassword"
              @click:append="changeShowPassword('showMatchPassword')"
            />
          </v-col>
        </v-row>
      </form>
      <v-row class="mt-5">
        <v-col cols="12">
          <v-btn large
                 color="primary"
                 class="rounded-card-small normal-btn-text"
                 @click="alterarSenha"
                 :loading="loadingButton"
                 :disabled="$v.$invalid">
            Redefinir senha
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {
  maxLength, minLength, required, sameAs,
} from 'vuelidate/lib/validators';
import { isPasswordValid } from '@/utils/validate-password';
import LoginService from '../service/login-service';

export default {
  data() {
    return {
      showPassword: false,
      showMatchPassword: false,
      loading: false,
      loadingButton: false,
      chave: this.$route.params.chave,
      password: null,
      matchPassword: null,
      passwordMessages: ['Deve possuir letra maiúscula, minúscula e números. Entre 8 e 30 caracteres'],
    };
  },
  computed: {
    passwordType() {
      return (varType) => {
        if (varType) {
          return 'text';
        }
        return 'password';
      };
    },
    passwordIcon() {
      return (varType) => {
        if (varType) {
          return 'mdi-eye-off';
        }
        return 'mdi-eye';
      };
    },
  },
  methods: {
    validate() {
      this.loading = true;
      LoginService.validarChave(this.chave)
        .then((response) => {
          if (!response.data.valido) {
            this.$toast('O link acessado não é mais válido', { color: 'error' });
            this.sendToLogin();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    alterarSenha() {
      this.loadingButton = true;
      LoginService.redefinirSenha(this.chave, this.buildBody())
        .then(() => {
          this.$toast('A sua senha foi alterada com sucesso', { color: 'success' });
          this.sendToLogin();
        })
        .finally(() => {
          this.loadingButton = false;
        });
    },
    buildBody() {
      return {
        password: this.password,
        matchPassword: this.matchPassword,
      };
    },
    sendToLogin() {
      this.$router.push({ name: 'login' });
    },
    changeShowPassword(passwordField) {
      this[passwordField] = !this[passwordField];
    },
  },
  created() {
    if (!this.chave) {
      this.sendToLogin();
    }
    this.validate();
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(30),
      passwordValid(value) {
        return isPasswordValid(value);
      },
    },
    matchPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
};
</script>

<style scoped>
.redefinir-senha-container {
  max-width: 450px;
}
</style>
