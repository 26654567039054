const SIMPLE_TYPES = ['d', 'g', 'c', 'search'];

const TYPES = {
  d: {
    description: 'Departamentos',
    id: 'd',
    path: '/departamentos/completo/',
    eventResource: 'DEPARTAMENTO',
    queryFieldBuilder: value => `departamento.id = ${value}`,
  },
  g: {
    description: 'Grupos',
    id: 'g',
    path: '/grupos/completo/',
    eventResource: 'GRUPO',
    queryFieldBuilder: value => `grupo.id = ${value}`,
  },
  c: {
    description: 'Categorias',
    id: 'c',
    path: '/categorias/completo/',
    eventResource: 'CATEGORIA',
    queryFieldBuilder: value => `categoria.id = ${value}`,
  },
  p: {
    description: 'Promoções',
    id: 'p',
    path: null,
    eventResource: 'PROMOCAO',
    queryFieldBuilder: () => 'promocao.ativo is true',
  },
  s: {
    description: 'Queima de estoque',
    id: 's',
    path: null,
    eventResource: 'QUEIMA_ESTOQUE',
    queryFieldBuilder: () => 'promocao.ativo is true and promocao.percentualDesconto >= 0.3',
  },
  f: {
    description: 'Favoritos',
    id: 'f',
    path: null,
    eventResource: 'FAVORITOS',
    queryFieldBuilder: () => 'ativo is true',
    customQueryParam: {
      favorito: true,
    },
  },
  search: {
    description: 'Busca',
    id: 'search',
    path: null,
    eventResource: 'BUSCA_OFERTA',
    queryField: null,
    queryFieldBuilder: null,
  },
};

export {
  TYPES,
  SIMPLE_TYPES,
};
