import Vue from 'vue';

import PxStatusNegociacao from './PxStatusNegociacao.vue';
import PxEspecificacaoProdutoComparavel from './PxEspecificacaoProdutoComparavel.vue';
import PxPopoverFornecedor from './PxPopoverFornecedor.vue';
import PxAutocompleteComprador from './PxAutocompleteComprador.vue';
import PxAutocompleteFornecedorCotacao from './PxAutocompleteFornecedorCotacao.vue';
import PxAutocompleteCotacaoProduto from './PxAutocompleteCotacaoProduto.vue';
import PxListaSelecaoFornecedores from './lista-selecao-fornecedor/PxListaSelecaoFornecedores.vue';
import PxIndicadorCompra from './PxIndicadorCompra.vue';
import PxAutocompleteUserGrupo from './PxAutocompleteUserGrupo.vue';
import PxRespostaPrazoPreco from './PxRespostaPrazoPreco.vue';
import PxRefresh from './PxRefresh.vue';
import PxFornecedorScore from './PxFornecedorScore.vue';
import PxOpenPaginaFornecedor from './PxOpenPaginaFornecedor.vue';
import PxHistoricoPrecoProduto from './PxHistoricoPrecoProduto.vue';
import ListaCotacaoIdentificador from './ListaCotacaoIdentificador.vue';
import CotacaoRespostaSemEstoque from './CotacaoRespostaSemEstoque.vue';
import CotacaoRespostasProdutosEspecificacao from './CotacaoRespostasProdutosEspecificacao.vue';
import PxAutocompleteEntidade from './PxAutocompleteEntidade.vue';
import PxAutocompleteEntidadeUsuario from './PxAutocompleteEntidadeUsuario.vue';
import PxOfertaFoto from './PxOfertaFoto.vue';
import PxAutocompletePrazoPagamentoMarketplace from './PxAutocompletePrazoPagamentoMarketplace.vue';
import PxOpenOferta from './PxOpenOferta.vue';
import PxLoadingHorizontalCards from './PxLoadingHorizontalCards.vue';
import PxDataPromocao from './PxDataPromocao.vue';
import PxGoToLoginInline from './PxGoToLoginInline.vue';
import PxGoToLoginBlock from './PxGoToLoginBlock.vue';
import PxSeloMaisPorMenos from './PxSeloMaisPorMenos.vue';
import PxSeloDesconto from './PxSeloDesconto.vue';
import PxLineIndicator from './PxLineIndicator.vue';
import PxMunicipioEstado from './PxMunicipioEstado.vue';
import PxMarketplaceViewHeader from './PxMarketplaceViewHeader.vue';
import PxBannerCadastroEmail from './marketing/PxBannerCadastroEmail.vue';
import PxCardColor from './PxCardColor.vue';
import PxShareWhatsappBtn from './PxShareWhatsappBtn.vue';
import PxMenuInfo from './PxMenuInfo.vue';
import PxWhatsappSupportBtn from './PxWhatsappSupportBtn.vue';

Vue.component('pxStatusNegociacao', PxStatusNegociacao);
Vue.component('PxEspecificacaoProdutoComparavel', PxEspecificacaoProdutoComparavel);
Vue.component('PxPopoverFornecedor', PxPopoverFornecedor);
Vue.component('PxAutocompleteComprador', PxAutocompleteComprador);
Vue.component('PxAutocompleteFornecedorCotacao', PxAutocompleteFornecedorCotacao);
Vue.component('PxAutocompleteCotacaoProduto', PxAutocompleteCotacaoProduto);
Vue.component('PxListaSelecaoFornecedores', PxListaSelecaoFornecedores);
Vue.component('PxAutocompleteUserGrupo', PxAutocompleteUserGrupo);
Vue.component('PxIndicadorCompra', PxIndicadorCompra);
Vue.component('PxRespostaPrazoPreco', PxRespostaPrazoPreco);
Vue.component('PxRefresh', PxRefresh);
Vue.component('PxFornecedorScore', PxFornecedorScore);
Vue.component('PxOpenPaginaFornecedor', PxOpenPaginaFornecedor);
Vue.component('PxHistoricoPrecoProduto', PxHistoricoPrecoProduto);
Vue.component('PxListaCotacaoIdentificador', ListaCotacaoIdentificador);
Vue.component('PxCotacaoRespostaSemEstoque', CotacaoRespostaSemEstoque);
Vue.component('PxCotacaoRespostasProdutosEspecificacao', CotacaoRespostasProdutosEspecificacao);
Vue.component('PxAutocompleteEntidade', PxAutocompleteEntidade);
Vue.component('PxAutocompleteEntidadeUsuario', PxAutocompleteEntidadeUsuario);
Vue.component('PxOfertaFoto', PxOfertaFoto);
Vue.component('PxAutocompletePrazoPagamentoMarketplace', PxAutocompletePrazoPagamentoMarketplace);
Vue.component('PxOpenOferta', PxOpenOferta);
Vue.component('PxLoadingHorizontalCards', PxLoadingHorizontalCards);
Vue.component('PxDataPromocao', PxDataPromocao);
Vue.component('PxGoToLoginInline', PxGoToLoginInline);
Vue.component('PxGoToLoginBlock', PxGoToLoginBlock);
Vue.component('PxSeloMaisPorMenos', PxSeloMaisPorMenos);
Vue.component('PxSeloDesconto', PxSeloDesconto);
Vue.component('PxLineIndicator', PxLineIndicator);
Vue.component('PxMunicipioEstado', PxMunicipioEstado);
Vue.component('PxMarketplaceViewHeader', PxMarketplaceViewHeader);
Vue.component('PxBannerCadastroEmail', PxBannerCadastroEmail);
Vue.component('PxCardColor', PxCardColor);
Vue.component('PxShareWhatsappBtn', PxShareWhatsappBtn);
Vue.component('PxMenuInfo', PxMenuInfo);
Vue.component('PxWhatsappSupportBtn', PxWhatsappSupportBtn);
