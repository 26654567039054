<template>
  <v-menu
  content-class="sugestao-card"
  absolute
  close-on-click
  close-on-content-click
  :position-x="xValue"
  :position-y="yValue"
  v-model="localValue"
  transition="slide-y-transition"
>
  <v-card tile :width="width" style="opacity: 1 !important;">
    <v-skeleton-loader
      :loading="loading"
      type="list-item-avatar-two-line@3"
    >
      <v-list v-if="sugestoes.content" class="pb-0 pt-0">
      <template v-for="(sugestao, index) in sugestoes.content">
        <v-list-item :key="sugestao.id" @click="onClickItem(sugestao)">
          <v-list-item-avatar class="square-avatar">
            <px-oferta-foto max-height="40"
                            max-width="40"
                            class="d-flex align-center justify-center"
                            :foto="sugestao.foto"/>
          </v-list-item-avatar>
          <v-list-item-content class="list-item-oferta-title-menu">
            <span>{{ sugestao.titulo }}</span>
            <div>
              <v-chip small
                      @click.stop.prevent="onClickCategoria(sugestao.categoria)">
                {{ sugestao.categoria.descricao }}
                <v-icon right small>
                  mdi-arrow-right
                </v-icon>
              </v-chip>
            </div>
            <div v-if="isMobile" class="mt-2">
              <span class="font-weight-bold">
              {{ precoAVista(sugestao) | currency }}
            </span>
              <div>
                <small>
                  à vista
                </small>
              </div>
            </div>
          </v-list-item-content>
          <v-list-item-content v-if="!isMobile">
            <span class="font-weight-bold">
              {{ precoAVista(sugestao) | currency }}
            </span>
            <div>
              <small>
                à vista
              </small>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index !== (sugestoes.content.length - 1)"
                   :key="`div-${sugestao.id}`"/>
      </template>
    </v-list>
    </v-skeleton-loader>
  </v-card>
</v-menu>
</template>

<script>
import { goToOfertasCategoria } from '../../services/route-oferta-service';

const FIX_MARGIN = 15;

export default {
  props: {
    value: Boolean,
    loading: Boolean,
    sugestoes: Object,
  },
  data() {
    return {
      xValue: null,
      yValue: null,
      width: null,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
    precoAVista() {
      return item => (item.promocaoAtiva
        ? item.precoPromocionalAVista : item.precoAVista);
    },
  },
  methods: {
    onClickCategoria(categoria) {
      this.$emit('clickitem');
      this.localValue = false;
      goToOfertasCategoria(categoria.id, false);
    },
    onClickItem(item) {
      this.$emit('clickitem');
      this.$router.push({
        name: 'public.ofertas.detalhes',
        params: {
          id: item.id,
        },
      });
    },
    setMenuPosition() {
      const searchText = document.getElementById('search-text-field');
      const container = searchText.parentElement.parentElement.getBoundingClientRect();
      this.xValue = container.left;
      this.yValue = container.top + container.height + FIX_MARGIN;
      this.width = container.width;
    },
  },
  watch: {
    localValue(newValue) {
      if (newValue) {
        this.setMenuPosition();
      }
    },
  },
};
</script>


<style scoped>
.sugestao-card {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 1px 3px 12px #00000029, 1px 3px 12px #00000029 !important;
}

.list-item-oferta-title-menu {
  min-width: 65%;
}
</style>
