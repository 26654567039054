<template>
  <div class="mt-8 ml-5">
    <div class="headline font-weight-bold-2">Perguntas</div>
    <template v-if="isLogged">
      <v-row dense>
        <v-col cols="12">
          <v-alert
            class="mt-5"
            type="info"
            color="primary"
            outlined
            dismissible>
            Todas as perguntas são enviadas de forma pública ao fornecedor.
            Suas informações pessoais não serão exibidas.
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-textarea
            outlined
            auto-grow
            hide-details
            class="rounded-card-small"
            v-model="pergunta"
            :error="$v.pergunta.$error"
            @blur="$v.pergunta.$touch()"
            label="Escreva aqui sua pergunta"
            rows="1"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="text-right">
          <v-btn class="rounded-card-small"
                 color="primary"
                 @click="onSendDuvida"
                 :loading="loading">
            Enviar
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <px-oferta-duvidas-list ref="duvidasList"
                            :oferta="oferta"
                            :method-getter-comprador="ofertaService.getDuvidas"/>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import CompradorService from '@/api/comprador-service';
import { mapGetters } from 'vuex';
import OfertaService from '../oferta-service';

export default {
  mixins: [validationMixin],
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      company: null,
      pergunta: null,
      loading: false,
      ofertaService: OfertaService,
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
  methods: {
    getComprador() {
      CompradorService.getComprador()
        .then(({ data }) => {
          [this.company] = data;
        });
    },
    clearFormData() {
      this.pergunta = null;
      this.$v.$reset();
    },
    onCreateDuvida() {
      this.$refs.duvidasList.onChangeOptions();
    },
    buildPayload() {
      return {
        pergunta: this.pergunta,
        company: this.company,
      };
    },
    onSendDuvida() {
      if (this.validateForm()) {
        return;
      }
      this.loading = true;
      OfertaService.criarDuvida(this.oferta.id, this.buildPayload())
        .then(() => {
          this.onCreateDuvida();
          this.clearFormData();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.isLogged) {
      this.getComprador();
    }
  },
  validations: {
    pergunta: {
      required,
    },
  },
};
</script>
