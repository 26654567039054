import Vue from 'vue';
import VueRouter from 'vue-router';
import loginRoute from './login';
import redefinirSenha from './redefinir-senha';
import mainRoute from './main/main-router';
import publicRoute from './main/public-router';
import googleAnalytcs from './google-analytics';
import { DEFAULT_TITLE, DEFAULT_META_DESCRIPTION } from '../constants/meta-tags';

Vue.use(VueRouter);

const routes = [
  loginRoute,
  redefinirSenha,
  mainRoute,
  publicRoute,
  {
    path: '*',
    redirect: { name: 'login' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

if (process.env.VUE_APP_GA_KEY) {
  window.ga('create', process.env.VUE_APP_GA_KEY, 'auto');
  router.afterEach(googleAnalytcs);
}

const setMetaDescription = (to) => {
  const description = to.meta.metaDescription || DEFAULT_META_DESCRIPTION;
  document.querySelector('meta[name="description"]')
    .setAttribute('content', description);
};

const setDefaultTitleMetaTags = (to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  setMetaDescription(to);
  next();
};

router.beforeEach(setDefaultTitleMetaTags);

export default router;
