<template>
  <div class="body-1">
    <v-skeleton-loader
      :loading="loading"
      type="list-item-two-line"
    >
      <div class="mb-5">
        <template v-if="freteOptions.length">
          <v-list dense>
            <v-list-item class="ml-0 pl-0" dense
                         v-for="option in freteOptions" :key="option.id">
              <v-list-item-content>
                <template v-if="option.picture">
                  <v-img class="logo-transportadora"
                         :src="option.picture"/>
                </template>
                <template v-else>
                  {{ optionDescription(option) }}
                  <div class="body-2 green--text"
                       v-if="option.valorFrete && option.freteGratis">
                    Grátis a partir de {{ option.compraMinima | currency }}
                  </div>
                </template>
              </v-list-item-content>
              <v-list-item-content>
                {{ option.name }}
              </v-list-item-content>
              <v-list-item-content>
                <div class="d-inline-block text-right">
                  <div class="font-weight-bold">
                    {{ option.valorFrete | currency }}
                  </div>
                  <div class="body-2">
                    entrega em {{ option.prazoEmbarque }} dias
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </div>
    </v-skeleton-loader>

    <template v-if="!isLogged">
      <px-oferta-info-frete-calcular class="mt-3"
                                     :loading="loading"
                                     @change="onChangeCep"/>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OfertaService from '../oferta-service';
import OfertaInfoFreteCalcular from './OfertaInfoFreteCalcular.vue';

export default {
  props: {
    variacao: {
      type: Object,
      required: true,
    },
    oferta: {
      type: Object,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    classOfertaGratis: {
      type: String,
      default: '',
    },
    fraseOfertaFreteGratis: {
      type: String,
      default: 'Esta empresa oferece frete grátis a partir de',
    },
    comprador: Object,
  },
  data() {
    return {
      loading: false,
      frete: null,
      freteOptions: [],
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    optionDescription() {
      return option => (option.modoFrete === 'ENTREGUE_FORNECEDOR'
        ? 'Entregue pelo fornecedor' : 'Retirar no fornecedor');
    },
  },
  methods: {
    onChangeCep(cep) {
      this.getFreteCep(cep);
    },
    getCurrentVariacaoId() {
      if (this.oferta.variacoes.length === 1) {
        const [variacao] = this.oferta.variacoes;
        return variacao.id;
      }
      // se não tiver variação selecionada, buscamos a primeira para calcular o frete
      if (this.variacao === this.oferta) {
        const [variacao] = this.oferta.variacoes;
        return variacao.id;
      }
      return this.variacao.id;
    },
    getFreteCep(cep, company) {
      this.loading = true;
      const payload = {
        company,
        quantidade: this.variacao.quantidade,
        cep,
      };
      OfertaService.getFreteCepIntegracao(this.getCurrentVariacaoId(), payload)
        .then(({ data }) => {
          this.freteOptions = data;
          this.$emit('loaded', this.freteOptions);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateFrete() {
      if (this.isLogged && this.comprador) {
        this.getFreteCep(this.comprador.endereco.cep, this.comprador);
      }
    },
  },
  watch: {
    comprador(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.getFreteCep(this.comprador.endereco.cep);
    },
    variacao(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.updateFrete();
    },
  },
  created() {
    this.updateFrete();
  },
  components: {
    pxOfertaInfoFreteCalcular: OfertaInfoFreteCalcular,
  },
};
</script>
