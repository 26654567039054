<template>
<div class="selo-desconto d-flex align-center flex-column pt-1 pb-1 pl-3 white--text dense-title-1">
    <div><b>{{ desconto }}%</b></div>
    <div><b>off</b></div>
  </div>
</template>

<script>
import currency from 'currency.js';

export default {
  props: {
    oferta: Object,
  },
  computed: {
    desconto() {
      if (!this.oferta.promocao) {
        return 0;
      }
      const desconto = currency(this.oferta.promocao.percentualDesconto)
        .multiply(100)
        .value;
      if (desconto < 0) {
        return 0;
      }
      return Math.round(desconto);
    },
  },
};
</script>

<style scoped>
.selo-desconto {
  width: 77px;
  height: 55px;
  background:
    transparent linear-gradient(264deg, #EF4F1F 0%, #EFB11F 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px 0 0 10px;
}
</style>
