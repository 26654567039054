<template>
  <div>
    <div class="d-flex">
      <div class="d-flex flex-grow-1">
        <span v-title v-meta-description class="headline font-weight-medium">
          {{ oferta.titulo }}
        </span>
<!--        todo: ver o que fazer com o open oferta-->
<!--        <px-open-oferta v-if="isPartialView" large :oferta="oferta"/>-->
      </div>
      <div class="d-flex align-center" v-if="isLogged">
        <px-oferta-favoriat-btn :oferta="oferta"/>
      </div>
    </div>
    <div>
      <span class="body-2">Cod. {{ oferta.codigo }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { goToOfertasCategoria } from '../../../services/route-oferta-service';
import OfertaFavoriatBtn from './OfertaFavoriatBtn.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    isPartialView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
  methods: {
    onClickCategoria() {
      goToOfertasCategoria(this.oferta.categoria.id, false);
    },
  },
  components: {
    pxOfertaFavoriatBtn: OfertaFavoriatBtn,
  },
};
</script>
