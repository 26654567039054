<template>
  <div>
    <div @click="onClick">
      <v-icon large class="mb-1" v-if="!value">mdi-chevron-right</v-icon>
      <v-icon large class="mb-1" v-else>mdi-chevron-up</v-icon>
      <slot/>
    </div>
    <v-expand-transition>
      <div v-if="value">
        <slot name="content"/>
      </div>
    </v-expand-transition>

  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  methods: {
    onClick() {
      this.localValue = !this.localValue;
    },
  },
};
</script>
