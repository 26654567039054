import Vue from 'vue';
import { first, last } from 'lodash';
import moment from 'moment';

export default {
  data() {
    return {
      loadingConfig: {
        text: 'Carregando…',
        color: '#305CA5',
        maskColor: 'rgba(255, 255, 255, 0.4)',
      },
    };
  },
  methods: {
    tooltipFormatter(item) {
      return `${first(item.data)}: ${Vue.filter('currency')(last(item.data))}`;
    },
    tooltipFormatterAxis(item) {
      const axisItem = first(item);
      return `${first(axisItem.data)}: ${Vue.filter('currency')(last(axisItem.data))}`;
    },
    simpleTooltipCurrencyFormatter(item) {
      return `${item.name}: ${Vue.filter('currency')(item.data)}`;
    },
    decimalNumberFormatter(item) {
      return `${item.name}: ${Vue.filter('number')(item.data, '0.00')}`;
    },
    axisFormatterTruncate(qtd) {
      return item => Vue.filter('truncate')(item, qtd);
    },
    axisMoneyFormatter(label) {
      return Vue.filter('currency')(label);
    },
    getDataInicial() {
      return moment().subtract(1, 'year').startOf('month');
    },
    showChartLoading(reference) {
      this.$refs[reference].showLoading(this.loadingConfig);
    },
    hideChartLoading(reference) {
      this.$refs[reference].hideLoading();
    },
  },
};
