import Main from '@/modules/main/Main.vue';
import Header from '@/modules/header/Header.vue';
import store from '@/store';
import Footer from '@/modules/footer/Footer.vue';

import { INITIALIZE_FROM_COOKIES_AC, SET_REDIRECT_ACT } from '@/store/auth/actions.type';
import { FETCH_USER } from '@/store/user/actions.type';


const beforeEnterAuth = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    if (store.getters.tokenFromStorage) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC);
      return store.dispatch(`user/${FETCH_USER}`)
        .then(() => next());
    }
    store.dispatch(SET_REDIRECT_ACT, to.fullPath);
    return next();
  }
  return store.dispatch(`user/${FETCH_USER}`)
    .then(() => next());
};

export default {
  path: '/',
  name: 'public',
  redirect: { name: 'public.home' },
  components: {
    default: Main,
    header: Header,
    footer: Footer,
  },
  children: [
    {
      path: 'q/:type/:value',
      name: 'public.ofertas',
      components: {
        default: () => import(/* webpackChunkName: "ofertas" */ '@/modules/ofertas/Ofertas.vue'),
      },
    },
    {
      path: 'oferta/:id',
      name: 'public.ofertas.detalhes',
      components: {
        default: () => import(/* webpackChunkName: "ofertas-detalhes" */ '@/modules/ofertas/oferta/Oferta.vue'),
      },
    },
    {
      path: 'home',
      name: 'public.home',
      components: {
        default: () => import(/* webpackChunkName: "home" */ '@/modules/home-page/HomePage.vue'),
      },
    },
    {
      path: 'fornecedor/:id',
      name: 'public.fornecedor',
      components: {
        default: () => import(/* webpackChunkName: "fornecedor" */ '@/modules/fornecedor/Fornecedor.vue'),
      },
    },
    {
      path: 'cadastro/:cnpj',
      name: 'public.cadastro',
      meta: {
        title: 'Criar conta | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "cadastro-form" */ '@/modules/cadastro/CadastroCompradorForm.vue'),
      },
    },
    {
      path: 'criar-conta',
      name: 'public.criar-conta',
      meta: {
        title: 'Criar conta | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "cadastro-form" */ '@/modules/cadastro/CadastroCompradorForm.vue'),
      },
    },
    {
      path: 'cadastro-sucesso',
      name: 'public.cadastro.sucesso',
      components: {
        default: () => import(/* webpackChunkName: "cadastro-sucesso" */ '@/modules/cadastro/CadastroCompradorSucesso.vue'),
      },
    },
    {
      path: 'central-ajuda',
      name: 'public.central-ajuda',
      meta: {
        title: 'Central de ajuda | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "atendimento" */ '@/modules/institucional/Atendimento.vue'),
      },
    },
    {
      path: 'quem-somos',
      name: 'public.quem-somos',
      meta: {
        title: 'Quem somos | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "quem-somos" */ '@/modules/institucional/QuemSomos.vue'),
      },
    },
    {
      path: 'para-empresas',
      name: 'public.para-empresas',
      meta: {
        title: 'Para empresas | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "para-empresas" */ '@/modules/institucional/ParaEmpresas.vue'),
      },
    },
    {
      path: 'para-fornecedores',
      name: 'public.para-fornecedores',
      meta: {
        title: 'Para fornecedores | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "para-fornecedores" */ '@/modules/institucional/ParaFornecedores.vue'),
      },
    },
    {
      path: 'faq',
      name: 'public.faq',
      meta: {
        title: 'FAQ | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "faq" */ '@/modules/institucional/FAQ.vue'),
      },
    },
    {
      path: 'agradecimento-news',
      name: 'public.agradecimento-news',
      components: {
        default: () => import(/* webpackChunkName: "agradecimento-form" */ '@/modules/integration/rd/AgradecimentoFromRd.vue'),
      },
    },
    {
      path: 'cadastro-news',
      name: 'public.cadastro-news',
      components: {
        default: () => import(/* webpackChunkName: "cadastro-news" */ '@/modules/integration/rd/RdFormSubscriptionPage.vue'),
      },
    },
    {
      path: 'avaliacao/:hash',
      name: 'public.compra.avaliacao',
      components: {
        default: () => import(/* webpackChunkName: "avaliacao" */ '@/modules/avaliacao/AvaliacaoCompra.vue'),
      },
    },
    {
      path: '/comparacao',
      name: 'public.comparacao',
      components: {
        default: () => import(/* webpackChunkName: "comparacao" */ '@/modules/compare/CompareView.vue'),
      },
    },
    {
      path: '/lp/materiais-escritorio',
      name: 'public.landing-page.materiais-escritorio',
      meta: {
        title: 'Materiais de escritório | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-escritorio" */ '@/modules/landing-page/LandingPageMaterialEscritorio.vue'),
      },
    },
    {
      path: '/lp/papel-higienico',
      name: 'public.landing-page.papel-higienico',
      meta: {
        title: 'Produtos de higiene | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-papelhigienico" */ '@/modules/landing-page/LandingPagePapelHigienico.vue'),
      },
    },
    {
      path: '/lp/granalhas',
      name: 'public.landing-page.granalhas',
      meta: {
        title: 'Granalhas | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-granalha" */ '@/modules/landing-page/LandingPageGranalha.vue'),
      },
    },
    {
      path: '/lp/tec-granalhas',
      name: 'public.landing-page.tec-granalhas',
      meta: {
        title: 'Granalhas | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-granalha-tec" */ '@/modules/landing-page/tecjato/LandingPageGranalhaTecJato.vue'),
      },
    },
    {
      path: '/lp/brasil',
      name: 'public.landing-page.brasil',
      meta: {
        title: 'Filmes PVC | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-granalha-tec" */ '@/modules/landing-page/brasil-sul/LandingPageBrasilSul.vue'),
      },
    },
    {
      path: '/lp/colatech',
      name: 'public.landing-page.brasil',
      meta: {
        title: 'Colatech | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-granalha-tec" */ '@/modules/landing-page/colatech/LandingPageColatech.vue'),
      },
    },
    {
      path: '/lp/max-granalhas',
      name: 'public.landing-page.max-granalhas',
      meta: {
        title: 'Granalhas | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-granalha-tec" */ '@/modules/landing-page/maxjato/LandingPageGranalhaMaxJato.vue'),
      },
    },
    {
      path: '/lp/fios-eletricos',
      name: 'public.landing-page.fios-eletricos',
      meta: {
        title: 'Fios e Cabos Elétricos | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-fioseletricos" */ '@/modules/landing-page/LandingPageFiosEletricos.vue'),
      },
    },
    {
      path: '/lp/produtos-limpeza',
      name: 'public.landing-page.produtos-limpeza',
      meta: {
        title: 'Limpeza, Higiene e Descartáveis | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-limpeza" */ '@/modules/landing-page/LandingPageProdutoLimpeza.vue'),
      },
    },
    {
      path: '/lp/oleos-hidraulicos',
      name: 'public.landing-page.oleos-hidraulicos',
      meta: {
        title: 'Óleos Hidráulicos | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-oleos" */ '@/modules/landing-page/LandingPageOleosHidraulicos.vue'),
      },
    },
    {
      path: '/lp/stretch',
      name: 'public.landing-page.stretch',
      meta: {
        title: 'Plástico Filme Stretch | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-stretch" */ '@/modules/landing-page/LandingPageStretch.vue'),
      },
    },
    {
      path: '/lp/ligas-metalicas',
      name: 'public.landing-page.ligas-metalicas',
      meta: {
        title: 'Ligas metálicas | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-ligas-metalicas" */ '@/modules/landing-page/LandingPageLigasMetalicas.vue'),
      },
    },
    {
      path: '/lp/filme-stretch',
      name: 'public.landing-page.filme-stretch',
      meta: {
        title: 'Plástico Filme Stretch | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-stretch" */ '@/modules/landing-page/LandingPageStretchSemPreco.vue'),
      },
    },
    {
      path: '/lp/suprimentos',
      name: 'public.landing-page.suprimentos',
      meta: {
        title: 'Suprimentos | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-suprimentos" */ '@/modules/landing-page/LandingPageSuprimentos.vue'),
      },
    },
    {
      path: '/lp/scarpim',
      name: 'public.landing-page.scarpim',
      meta: {
        title: 'Scarpim | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-scarpim" */ '@/modules/landing-page/scarpim/LandingPageScarpim.vue'),
      },
    },
    {
      path: '/lp/tecnolatina',
      name: 'public.landing-page.scarpim',
      meta: {
        title: 'Tecnolatina | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-tecnolatina" */ '@/modules/landing-page/tecnolatina/LandingPageTecnolatina.vue'),
      },
    },
    {
      path: '/lp/pumatek',
      name: 'public.landing-page.pumatek',
      meta: {
        title: 'Pumatek | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-pumatek" */ '@/modules/landing-page/pumatek/LandingPagePumatek.vue'),
      },
    },
    {
      path: '/lp/genova',
      name: 'public.landing-page.genova',
      meta: {
        title: 'Genova | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-genova" */ '@/modules/landing-page/genova/LandingPageGenova.vue'),
      },
    },
    {
      path: '/lp/cps',
      name: 'public.landing-page.cps',
      meta: {
        title: 'CPS Embalagens | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-cps" */ '@/modules/landing-page/cps/LandingPageCps.vue'),
      },
    },
    {
      path: '/lp/winoa',
      name: 'public.landing-page.winoa',
      meta: {
        title: 'Winoa | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-winoa" */ '@/modules/landing-page/winoa/LandingPageWinoa.vue'),
      },
    },
    {
      path: '/termos-uso',
      name: 'public.termos-uso',
      meta: {
        title: 'Termos de uso | Proxpect',
      },
      components: {
        default: () => import(/* webpackChunkName: "lp-suprimentos" */ '@/modules/termos/TermoUso.vue'),
      },
    },
  ],
  beforeEnter: beforeEnterAuth,
};
