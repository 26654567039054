<template>
  <div>
    <v-menu
      open-on-hover
      content-class="rounded-right menu-overflow-hide menu-shadow"
      absolute
      :position-x="xValue"
      :position-y="yValue"
      v-model="menuOpened"
      :close-on-content-click="true"
      transition="slide-x-transition"
      :min-width="width"
      :max-width="width"
      offset-x
    >
      <template v-slot:activator="{ on }">
        <v-list-item v-on="{ ...on, mouseenter: onClickMenu }"
                     @mousedown.middle="goToOfertas(departamento, true)"
                     @click="goToOfertas(departamento)">
          <v-list-item-content>{{ departamento.descricao }}</v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-card tile min-height="400px">
        <v-card-text class="text--primary">
          <v-row class="menu-height-overflow">
            <v-col cols="12" sm="4" v-for="grupo in departamento.grupos" :key="grupo.id">
              <px-grupo-menu @close="emitClose" :grupo="grupo"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import GrupoMenu from './GrupoMenu.vue';
import { goToOfertasDepartamento } from '../../services/route-oferta-service';

export default {
  props: {
    departamento: Object,
  },
  data: () => ({
    menuOpened: false,
    xValue: 0,
    yValue: 0,
  }),
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    width() {
      if (this.isSmall) {
        return '300px';
      }
      if (this.isSm) {
        return '650px';
      }
      return '800px';
    },
  },
  methods: {
    goToOfertas(departamento, newTab = false) {
      goToOfertasDepartamento(departamento.id, newTab);
    },
    emitClose() {
      this.$emit('close');
    },
    onClickMenu() {
      setTimeout(() => {
        const departamentosMenu = document.getElementById('menuDepartamentos');
        const menuPosition = departamentosMenu.getBoundingClientRect();
        this.xValue = menuPosition.left + menuPosition.width;
        this.yValue = menuPosition.top;
        this.menuOpened = true;
      });
    },
  },
  components: {
    pxGrupoMenu: GrupoMenu,
  },
};
</script>
