<template>
  <span>
    <slot name="activator" v-bind:on="{ click: onClick }"></slot>
  </span>
</template>

<script>
import { buildLoginUrl } from '../utils/login-utils';

export default {
  methods: {
    onClick() {
      if (this.$router.currentRoute.name === 'login') {
        return;
      }
      window.location = buildLoginUrl();
    },
  },
};
</script>
