import axios from '../../../marketplace/axios-marketplace';

const getAlerta = id => axios.get(`/ofertas/${id}/alertas`);
const saveAlerta = (id, data) => {
  if (data.id) {
    return axios.put(`/ofertas/${id}/alertas/${data.id}`, data);
  }
  return axios.post(`/ofertas/${id}/alertas`, data);
};

export default {
  getAlerta,
  saveAlerta,
};
