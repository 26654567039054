import {
  SET_USER, LOADING,
} from './mutations.type';

export default {
  [SET_USER](state, user) {
    state.user = user;
  },
  [LOADING](state, loading) {
    state.loading = loading;
  },
};
