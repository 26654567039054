<template>
  <div>
    <div class="headline text--primary--color font-weight-bold mb-10">
      Cadastre-se aqui
    </div>
    <v-form
      class="login-content"
      ref="formCadastro"
    >
      <v-text-field
        solo
        hide-details
        class="required rounded-card-small"
        autocomplete="off"
        label="CNPJ"
        v-mask="['##.###.###/####-##']"
        :error="$v.cnpj.$error"
        @blur="$v.cnpj.$touch()"
        v-model="cnpj"
      />
    </v-form>
    <div slot="actions" class="mt-10">
      <v-btn block
             large
             class="rounded-card-small normal-btn-text"
             color="primary"
             @click="onClickCadastro"
             :disabled="$v.$invalid"
             :loading="loading">
        Continuar
      </v-btn>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { isValid } from '@/utils/validate-cnpj';
import { onlyNumbers } from '@/utils/regex-utils';

export default {
  data() {
    return {
      loading: false,
      cnpj: null,
    };
  },
  methods: {
    onClickCadastro() {
      this.$router.push({
        name: 'public.cadastro',
        params: {
          cnpj: onlyNumbers(this.cnpj),
        },
      });
    },
  },
  validations: {
    cnpj: {
      required,
      isValid,
    },
  },
};
</script>

<style scoped>
.login-content {
  padding-top: 15px;
}
</style>
