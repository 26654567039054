<template>
  <v-container class="mkt-content-container mt-5">
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && oferta">
      <v-row>
        <v-col cols="12">
          <px-oferta-breadcrumbs :categoria="oferta.categoria"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :sm="smFoto">
          <v-row dense no-gutters>
            <v-col cols="12">
              <px-oferta-foto :oferta="oferta"/>
              <div class="d-flex align-center justify-center body-2 mt-3">
                <px-oferta-info-share class="mt-5" :oferta="oferta"/>
              </div>
            </v-col>
            <v-col cols="12" v-if="!isMobile">
              <px-oferta-especificacao :oferta="oferta"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" :sm="smPreco">
          <px-oferta-info-preco v-if="oferta.ativo"
                                @comprador="changeComprador"
                                @addedcarrinho="addedCarrinho"
                                @clickalert="onClickAlert"
                                :comprador-default="compradorDefault"
                                :quantidade-default="quantidadeDefault"
                                :is-partial-view="isPartialView"
                                :oferta="oferta"/>
          <div v-else>
            <v-icon class="mr-2" color="error">mdi-alert-outline</v-icon>
            Este item não está disponível
          </div>
          <div class="pa-4">
            <px-whatsapp-support-btn :text="whatsappSupportText"/>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isMobile">
        <v-col cols="12">
          <px-oferta-especificacao :oferta="oferta" margin-class="mt-5"/>
        </v-col>
      </v-row>
      <div class="mt-5" v-if="!isPartialView">
        <px-oferta-historico-preco ref="historicoPreco" :oferta="oferta"/>
      </div>
      <v-row class="mt-5" v-if="!isPartialView">
        <v-col cols="12">
          <px-oferta-recomendacao :oferta="oferta"/>
        </v-col>
      </v-row>
      <v-row v-if="!isPartialView && !isLogged">
        <v-col cols="12">
          <px-banner-cadastro-email/>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!isPartialView">
        <v-col cols="12">
          <px-oferta-avaliacao-list :oferta="oferta"/>
        </v-col>
      </v-row>
      <v-row v-if="!isPartialView">
        <v-col cols="12">
          <px-oferta-duvidas :oferta="oferta"/>
        </v-col>
      </v-row>
      <v-row v-if="!isPartialView">
        <v-col cols="12">
          <px-home-page-block :class="`ml-5 ${blockMarginTop}`"
                              query="promocao.ativo is true and disponivel is true order by random"
                              title="Outras promoções"
                              :qtd-ofertas="20"/>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import EventService from '@/events';
import { EventTypes } from 'px-business-components';
import gridMixin from '@/mixins/grid-mixin';
import { mapGetters } from 'vuex';
import OfertaService from '../oferta-service';
import OfertaFoto from './OfertaFoto.vue';
import OfertaInfoPreco from './OfertaInfoPreco.vue';
import OfertaEspecificacao from './OfertaEspecificacao.vue';
import OfertaDuvidas from '../perguntas/OfertaDuvidas.vue';
import OfertaRecomendacao from './OfertaRecomendacao.vue';
import HomePageBlock from '../../home-page/HomePageBlock.vue';
import OfertaBreadcrumbs from './OfertaBreadcrumbs.vue';
import OfertaAvaliacaoList from './avaliacao/OfertaAvaliacaoList.vue';
import OfertaHistoricoPreco from './OfertaHistoricoPreco.vue';
import OfertaInfoShare from './OfertaInfoShare.vue';

export default {
  mixins: [gridMixin],
  props: {
    idOferta: {
      type: [String, Number],
      default: null,
    },
    isPartialView: {
      type: Boolean,
      default: false,
    },
    quantidadeDefault: {
      type: [String, Number],
      default: 0,
    },
    compradorDefault: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      oferta: null,
      compradorSelecionado: null,
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    blockMarginTop() {
      if (this.isMobile) {
        return 'mt-5';
      }
      return 'mt-12';
    },
    id() {
      return this.idOferta || this.$route.params.id;
    },
    smFoto() {
      if (this.isPartialView) {
        return '6';
      }
      return '7';
    },
    smPreco() {
      if (this.isPartialView) {
        return '6';
      }
      return '5';
    },
    whatsappSupportText() {
      return `Olá! Tenho uma dúvida sobre o produto ${this.oferta.titulo}. ${window.location}`;
    },
  },
  methods: {
    changeComprador(comprador) {
      this.compradorSelecionado = comprador;
    },
    addedCarrinho() {
      this.$emit('addedcarrinho');
    },
    fireEvent() {
      EventService.createAcessoTarget(axios, EventTypes.Resource.OFERTA,
        { id: this.id }, this.id);
    },
    getOferta() {
      this.loading = true;
      OfertaService.getOferta(this.id)
        .then(({ data }) => {
          this.oferta = data;
          this.fireEvent();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickAlert() {
      this.$refs.historicoPreco.openHistory();
    },
  },
  watch: {
    id(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.$router.go(this.$router.currentRoute);
    },
  },
  created() {
    this.getOferta();
  },
  components: {
    pxOfertaFoto: OfertaFoto,
    pxOfertaInfoPreco: OfertaInfoPreco,
    pxOfertaEspecificacao: OfertaEspecificacao,
    pxOfertaDuvidas: OfertaDuvidas,
    pxOfertaRecomendacao: OfertaRecomendacao,
    pxHomePageBlock: HomePageBlock,
    pxOfertaBreadcrumbs: OfertaBreadcrumbs,
    pxOfertaAvaliacaoList: OfertaAvaliacaoList,
    pxOfertaHistoricoPreco: OfertaHistoricoPreco,
    pxOfertaInfoShare: OfertaInfoShare,
  },
};
</script>
