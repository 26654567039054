import axios from '../../../marketplace/axios-marketplace';

const getAvaliacaoOferta = id => axios.get(`/ofertas/${id}/avaliacao`);
const getAvaliacaoOfertaList = (id, params) => axios.get(`/ofertas/${id}/avaliacoes-compras`, {
  params,
});

export default {
  getAvaliacaoOferta,
  getAvaliacaoOfertaList,
};
