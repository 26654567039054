import axios from './axios-login';

const logar = loginData => axios.post('/auth/login', loginData);
const solicitarSenha = data => axios.post('/open/users/reset-password', data);
const validarChave = chave => axios.get(`/open/users/reset-password/${chave}`);
const redefinirSenha = (chave, data) => axios.post(`/open/users/reset-password/${chave}`, data);

export default {
  logar,
  solicitarSenha,
  validarChave,
  redefinirSenha,
};
