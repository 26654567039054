<template>
  <div v-if="hasItems">
    <px-loading-horizontal-cards :loading="loading"/>
    <px-ofertas-slider :title="title"
                       :show-link="showLink"
                       :link="link"
                       :link-text="linkText"
                       :link-target="linkTarget"
                       :class-title="classTitle"
                       :open-new-tab="openNewTab"
                       :show-price="showPrice"
                       :ofertas="ofertas.content"/>
  </div>
</template>

<script>
import OfertaService from '@/modules/ofertas/oferta-service';
import OfertasSlider from './OfertasSlider.vue';

export default {
  props: {
    query: String,
    title: String,
    classTitle: {
      type: String,
    },
    openNewTab: {
      type: Boolean,
      default: false,
    },
    qtdOfertas: {
      type: Number,
      default: 30,
    },
    showLink: {
      type: Boolean,
      default: false,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    link: {
      type: String,
      default: '/',
    },
    linkText: {
      type: String,
      default: 'ver tudo',
    },
    linkTarget: {
      type: String,
      default: '_self',
    },
    queryFunction: {
      type: Function,
      default: OfertaService.queryOfertas,
    },
  },
  data() {
    return {
      loading: false,
      ofertas: {
        content: [],
        total: 0,
      },
    };
  },
  computed: {
    hasItems() {
      return this.ofertas.total > 0;
    },
  },
  methods: {
    getOfertas() {
      this.loading = true;
      this.queryFunction({
        query: this.query,
        offset: 0,
        limit: this.qtdOfertas,
      })
        .then(({ data }) => {
          this.ofertas = data;
          this.$emit('onload', data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getOfertas();
  },
  components: {
    pxOfertasSlider: OfertasSlider,
  },
};
</script>
