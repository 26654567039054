import Vue from 'vue';
import axios from 'axios';
import { authInterceptor, responseAuthInterceptor } from '@/plugins/axios/axios-auth-interceptor';
import { appIdInterceptor } from '@/plugins/axios/app-id-interceptor';
import ErrorInterceptor from '@/plugins/axios/axios-error-intecerptor';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_MARKETPLACE_API_URL,
});

axiosInstance.interceptors.request.use(authInterceptor);
axiosInstance.interceptors.response.use(null, responseAuthInterceptor);
axiosInstance.interceptors.request.use(appIdInterceptor);
axiosInstance.interceptors.response.use(null, ErrorInterceptor);

Vue.prototype.$httpMarketplace = axiosInstance;

export default axiosInstance;
