<template>
  <v-card outlined class="mt-12 ml-5 rounded-card">
    <v-card-text class="text--primary">
      <div class="d-flex flex-column align-center">
        <div class="body-1 font-weight-bold-2">
          <v-icon left color="warning">mdi-alert-outline</v-icon>
          Envie uma contraproposta
        </div>
        <div class="body-2 mt-3 text-center">
          Possui um preço melhor? Envie uma proposta para o fornecedor.
        </div>
        <div class="mt-8">
          <px-input-money
            label="Valor desejado"
            class="required rounded-card-small"
            ref="valorDesejado"
            :error="$v.alerta.valor.$error"
            @blur="$v.alerta.valor.$touch()"
            v-model="alerta.valor"/>
        </div>
        <div class="mt-8">
          <v-btn large
                 color="primary"
                 class="normal-btn-text rounded-tag-small elevation-0"
                 :loading="loading"
                 @click="onClickCreate">
            <v-icon left>mdi-bell-outline</v-icon>
            Enviar contraproposta
          </v-btn>
        </div>
      </div>
      <px-oferta-alerta-preco-email-dialog v-model="emailDialog"
                                           :alerta="alerta"
                                           @create="onCreateDialog"/>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import validationMixin from '@/mixins/validation-mixin';
import OfertaAlertaService from '@/modules/ofertas/oferta/alerta-preco/oferta-alerta-service';
import { NumberUtils } from 'px-components';
import OfertaAlertaPrecoEmailDialog from './OfertaAlertaPrecoEmailDialog.vue';

export default {
  mixins: [validationMixin],
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    alertaProp: {
      type: Object,
    },
  },
  data() {
    return {
      alerta: {
        valor: null,
        email: null,
      },
      emailDialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
  methods: {
    criarAlerta() {
      this.loading = true;
      OfertaAlertaService.saveAlerta(this.oferta.id, this.alerta)
        .then(({ data }) => {
          this.$emit('change', data);
          this.$toast('Você será notificado quando o preço baixar!', { color: 'success' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCreateDialog(email) {
      this.alerta.email = email;
      this.criarAlerta();
    },
    onClickCreate() {
      if (this.validateForm()) {
        return;
      }
      if (this.isLogged) {
        this.criarAlerta();
        return;
      }
      this.emailDialog = true;
    },
    setupUpdate() {
      this.alerta = { ...this.alertaProp };
      this.alerta.valor = NumberUtils.fixNumberInput(this.alerta.valor);
    },
  },
  created() {
    if (this.alertaProp) {
      this.setupUpdate();
    }
  },
  validations: {
    alerta: {
      valor: {
        required,
        minValue: value => value > 0,
      },
    },
  },
  components: {
    pxOfertaAlertaPrecoEmailDialog: OfertaAlertaPrecoEmailDialog,
  },
};
</script>
