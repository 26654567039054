<template>
  <div class="mt-3 pointer-cursor" @click="onClickAvaliacoes" v-if="!loading && avaliacao">
    <v-rating
      readonly
      dense
      half-increments
      class="d-inline"
      background-color="grey darken-1"
      length="5"
      size="20"
      :value="avaliacao.pontuacaoMedia"
    />
    {{ avaliacao.pontuacaoMedia | number('0.0') }}
  </div>
</template>

<script>
import AvalicaoOfertaService from './oferta-avaliacao-service';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avaliacao: null,
      loading: false,
    };
  },
  methods: {
    onClickAvaliacoes() {
      document.querySelector('#oferta-avaliacao-list')
        .scrollIntoView({ behavior: 'smooth' });
    },
    getAvaliacaoOferta() {
      this.loading = true;
      AvalicaoOfertaService.getAvaliacaoOferta(this.oferta.id)
        .then(({ data }) => {
          this.avaliacao = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getAvaliacaoOferta();
  },
};
</script>
