<template>
  <v-footer app color="white" class="footer" absolute>
    <v-row>
      <v-col cols="12" sm="2" offset-sm="1">
        <span class="title font-weight-bold-2">Institucional</span>
        <div class="mt-5 body-2">
          <div>
            <router-link class="body-2 no-decoration text--primary" to="/quem-somos">
              Quem somos
            </router-link>
          </div>
          <div class="mt-3">
            <router-link class="body-2 no-decoration text--primary" to="/para-empresas">
              Para empresas
            </router-link>
          </div>
          <div class="mt-3">
            <router-link class="body-2 no-decoration text--primary" to="/para-fornecedores">
              Para fornecedores
            </router-link>
          </div>
<!--          <div class="mt-3">-->
<!--            <a href="" class="no-decoration text&#45;&#45;primary">Parceiros</a>-->
<!--          </div>-->
        </div>
      </v-col>
      <v-col cols="12" sm="2">
        <span class="title font-weight-bold-2">Atendimento</span>
        <div class="mt-5 body-2">
          <div>
            <router-link class="body-2 no-decoration text--primary" to="/faq">
              Perguntas frequentes
            </router-link>
          </div>
<!--          <div class="mt-3">-->
<!--            <a href="" class="no-decoration text&#45;&#45;primary">Fale conosco</a>-->
<!--          </div>-->
<!--          <div class="mt-3">-->
<!--            <a href="" class="no-decoration text&#45;&#45;primary">Central de ajuda</a>-->
<!--          </div>-->
          <div class="mt-3">
            <router-link class="no-decoration text--primary" to="/central-ajuda">
              Fale conosco
            </router-link>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="2">
        <span class="title font-weight-bold-2">Fale conosco</span>
        <div class="mt-5 body-2">
          <div>
            Vendas: (48) 99212-0020
          </div>
          <div class="mt-3">
            Suporte: (48) 98439-4452
          </div>
          <div class="mt-3">
            contato@proxpect.com.br
          </div>
          <div class="mt-2">
            Atendimento de segunda à sexta das 8h às 18h.
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="2">
        <span class="title font-weight-bold-2">Serviços</span>
        <div class="mt-5 body-2">
          <div>
            <a href="https://fornecedor.proxpect.com.br"
               target="_blank"
               class="no-decoration text--primary">Portal Fornecedor</a>
          </div>
          <div class="mt-3">
            <a href="https://blog.proxpect.com.br"
               target="_blank"
               class="no-decoration text--primary">Blog</a>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="2">
        <span class="title font-weight-bold-2">Formas de pagamento</span>
        <div class="mt-5 body-1">
          <v-img
            width="80px"
            class="d-inline-block"
            src="../../assets/boleto-simbolo.jpg"
          />
          <v-img
            width="80px"
            class="d-inline-block ml-3"
            src="../../assets/pix-logo.png"
          />
        </div>
        <div class="mt-5">
          <v-btn
            target="_blank"
            href="https://www.instagram.com/proxpect/"
            class="mx-2"
            icon
            large
          >
            <v-icon large size="24px">
              mdi-instagram
            </v-icon>
          </v-btn>
          <v-btn
            target="_blank"
            href="https://www.linkedin.com/company/proxpect/"
            class="mx-2"
            icon
            large
          >
            <v-icon size="24px" large>
              mdi-linkedin
            </v-icon>
          </v-btn>
          <v-btn
            target="_blank"
            href="https://www.facebook.com/StartupProxpect"
            class="mx-2"
            icon
            large
          >
            <v-icon size="24px" large>
              mdi-facebook
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div style="width: 100%">
      <v-row class="mt-5">
        <v-col cols="12" offset-sm="1">
          <span class="caption">
            Copyrights {{ new Date().getFullYear() }} ©
            <strong>Proxpect</strong> - Todos os direitos reservados
          </span>
        </v-col>
      </v-row>
    </div>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
  }),
};
</script>
