<template>
  <div class="mt-5" v-if="isLogged">
    <a class="text--primary" @click="onClickOpen">
      <span class="decoration-link">Formas de pagamento do fornecedor&nbsp;</span>
      <v-icon>{{ icon }}</v-icon>
    </a>
    <v-expand-transition>
      v-if="isOpen" class="mt-2"
      <px-oferta-formas-pagamento-details v-if="isOpen"
                                          class="mt-2"
                                          :formas-pagamento="formasPagamento"/>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrazoPagamentoService from '@/api/prazo-pagamento-service';
import OfertaFormasPagamentoDetails from './OfertaFormasPagamentoDetails.vue';

export default {
  props: {
    oferta: Object,
  },
  data() {
    return {
      isOpen: false,
      formasPagamento: [],
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    icon() {
      if (this.isOpen) {
        return 'mdi-chevron-up';
      }
      return 'mdi-chevron-down';
    },
  },
  methods: {
    onClickOpen() {
      this.isOpen = !this.isOpen;
    },
    getPrazos() {
      PrazoPagamentoService.getPrazosMarketplacePageCompleto(this.oferta.configuracaoVenda.id,
        {
          limit: 20,
          offset: 0,
          query: 'order by valorMinimo',
        })
        .then(({ data }) => {
          this.formasPagamento = data.content;
        });
    },
  },
  created() {
    this.getPrazos();
  },
  components: {
    pxOfertaFormasPagamentoDetails: OfertaFormasPagamentoDetails,
  },
};
</script>
