<template>
  <v-autocomplete
    outlined
    hide-details
    clearable
    return-object
    no-filter
    hide-selected
    hide-no-data
    type="search"
    item-text="descricao"
    :label="label"
    autocomplete="off"
    v-model="localValue"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    @focus="onFocus"
    @blur="onBlur"
    v-bind="$attrs"
  />
</template>

<script>
import { debounce } from 'vuetify/lib/util/helpers';
import PrazoPagamentoService from '@/api/prazo-pagamento-service';

const DEFAULT_LIMIT = 100;
const DEFAULT_OFFSET = 0;

export default {
  props: {
    value: Object,
    label: {
      type: String,
      default: 'Prazo de pagamento',
    },
    configuracaoId: {
      type: [String, Number],
      required: true,
    },
    filter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      model: null,
      search: null,
      debounceSearch: null,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  watch: {
    search(val) {
      this.debounceSearch(val);
    },
  },
  methods: {
    buildFilter(val) {
      let filter = `descricao like "${val}%"`;

      if (this.filter) {
        filter = `${filter} and ${this.filter}`;
      }

      return `${filter} order by descricao`;
    },
    searchMethod(val) {
      if (this.localValue && this.localValue.descricao === val) {
        return;
      }

      if (val === null) {
        return;
      }

      this.loading = true;
      PrazoPagamentoService.getPrazosMarketplacePage(this.configuracaoId, {
        limit: DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
        query: this.buildFilter(val),
      })
        .then((response) => {
          this.items = response.data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onFocus() {
      if (this.items.length) {
        return;
      }
      this.searchMethod('');
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
  },
  created() {
    this.debounceSearch = debounce(this.searchMethod, 1000);
    if (this.value) {
      this.items = [this.value];
    }
  },
};
</script>
