<template>
  <div :class="`line-indicator ${lineClass} d-inline-block mb-1 ${contentClass}`"></div>
</template>

<script>
export default {
  props: {
    contentClass: {
      type: String,
      default: 'mx-2',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lineClass() {
      if (this.small) {
        return 'line-indicator-small';
      }
      return 'line-indicator-default';
    },
  },
};
</script>

<style>
.line-indicator {
  height: 0;
  border: 1px solid #8B8B8B;
  opacity: 1;
}

.line-indicator-default {
  width: 60px;
}

.line-indicator-small {
  width: 30px;
}
</style>
