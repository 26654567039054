import CarrinhoService from '@/modules/carrinho/carrinho-service';
import {
  GET_ITENS, ADD_ITEM, REMOVE_ITEM,
} from './actions.type';
import { SET_ITENS, LOADING, LOADING_ADD } from './mutations.type';

export default {
  [GET_ITENS](context) {
    context.commit(LOADING, true);
    CarrinhoService.getOfertasFlat()
      .then((response) => {
        context.commit(SET_ITENS, response.data);
      })
      .finally(() => {
        context.commit(LOADING, false);
      });
  },
  [ADD_ITEM](context, { payload }) {
    context.commit(LOADING_ADD, true);
    return CarrinhoService.addProduto(payload)
      .then((response) => {
        context.dispatch(GET_ITENS);
        return response.data;
      })
      .finally(() => {
        context.commit(LOADING_ADD, false);
      });
  },
  [REMOVE_ITEM](context, { id }) {
    context.commit(LOADING, true);
    return CarrinhoService.removeOferta(id)
      .then((response) => {
        context.dispatch(GET_ITENS);
        return response.data;
      })
      .finally(() => {
        context.commit(LOADING, false);
      });
  },
};
