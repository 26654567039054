<template>
  <span>{{ endereco.municipio.nome }} - {{ endereco.estado.sigla }}</span>
</template>

<script>
export default {
  props: {
    endereco: {
      type: Object,
      required: true,
    },
  },
};
</script>
