<template>
  <div class="ml-5">
    <div :class="`${marginClass} headline font-weight-bold-2`">
      Descrição
    </div>
    <div class="mt-5 body-1 large-text">
      {{ oferta.especificacao }}
    </div>
    <div v-if="oferta.marca && isLogged" class="mt-5 body-1">
      Marca: {{ oferta.marca }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    marginClass: {
      type: String,
      default: 'mt-24',
    },
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
};
</script>
