import {
  SHOW_BAR, CLOSE_BAR, CLEAR_OFERTAS, ADD_OFERTA, REMOVE_OFERTA, LOAD_INITIAL_STATE,
} from './mutations.type';

const STORAGE_KEY = 'px_compare_items';

export default {
  [SHOW_BAR](state) {
    state.showBar = true;
  },
  [CLOSE_BAR](state) {
    state.showBar = false;
  },
  [CLEAR_OFERTAS](state) {
    state.showBar = false;
    state.ofertas = [];
    localStorage.removeItem(STORAGE_KEY);
  },
  [ADD_OFERTA](state, oferta) {
    state.showBar = true;
    state.ofertas.push(oferta);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  },
  [LOAD_INITIAL_STATE](state) {
    const localState = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (!localState) {
      return;
    }
    state.showBar = localState.showBar;
    state.ofertas = localState.ofertas;
  },
  [REMOVE_OFERTA](state, id) {
    const index = state.ofertas.findIndex(oferta => oferta.id === id);
    state.ofertas.splice(index, 1);
    if (!state.ofertas.length) {
      state.showBar = false;
      localStorage.removeItem(STORAGE_KEY);
      return;
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  },
};
