<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-rating
          readonly
          dense
          half-increments
          class="d-inline"
          background-color="grey darken-1"
          length="5"
          size="20"
          :value="avaliacao.avaliacao.pontuacao"
        />
        <span class="body-1 font-weight-bold">
            Avaliado em: {{ avaliacao.avaliacaoCompra.dataResposta | date }}
          </span>
        <v-icon small>mdi-calendar</v-icon>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="body-2 large-text">
        {{ avaliacao.avaliacao.comentario | naoInformado }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    avaliacao: {
      type: Object,
      required: true,
    },
  },
};
</script>
