<template>
  <div class="bottom-bar d-flex justify-space-between align-center primary"
       v-if="showBar && !isCompareView">
    <div>
      <v-chip v-for="oferta in ofertas"
              :key="oferta.id"
              label
              color="white"
              class="rounded-tag-small mr-3 mt-3 mt-sm-1"
              close-icon="mdi-close" close @click:close="onCloseOferta(oferta)">
        <v-avatar left>
          <px-oferta-foto max-height="25"
                          max-width="25"
                          class="d-flex align-center justify-center"
                          :foto="oferta.foto"/>
        </v-avatar>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ oferta.titulo | truncate(20) }}</span>
          </template>
          <span>
            {{ oferta.titulo }}
          </span>
        </v-tooltip>
      </v-chip>
    </div>
    <div class="text-right mr-16">
      <v-btn outlined
             color="white"
             link
             to="/comparacao"
             class="mr-sm-5 rounded-tag-small normal-btn-text">
        Comparar
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn v-if="!isMobile" icon small
             color="white"
             @click="onClickClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn v-if="isMobile" outlined
             color="white"
             class="mt-3 rounded-tag-small normal-btn-text"
             @click="onClickClose">
        Fechar
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import gridMixin from '@/mixins/grid-mixin';
import {
  CLOSE_BAR_ACT, CLEAR_OFERTAS_ACT, REMOVE_OFERTA_ACT, LOAD_INITIAL_STATE_ACT,
} from '@/store/compare/actions.type';

export default {
  mixins: [gridMixin],
  computed: {
    ...mapGetters('compare', ['showBar', 'ofertas']),
    isCompareView() {
      return this.$route.name === 'public.comparacao';
    },
  },
  methods: {
    ...mapActions('compare', {
      closeBar: CLOSE_BAR_ACT,
      clearOfertas: CLEAR_OFERTAS_ACT,
      removeOferta: REMOVE_OFERTA_ACT,
      loadInitialState: LOAD_INITIAL_STATE_ACT,
    }),
    onClickClose() {
      this.clearOfertas();
    },
    onCloseOferta(oferta) {
      this.removeOferta(oferta);
    },
  },
  created() {
    this.loadInitialState();
  },
};
</script>

<style scoped>
.bottom-bar {
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 999;
}
</style>
