<template>
  <px-lateral-dialog v-model="dialog" width="450">
    <template v-slot:activator="{ on }">
      <a v-on="on" class="body-2">
        Esqueceu sua senha?
      </a>
    </template>
    <template v-slot:title>
      Solicitar nova senha
    </template>
    <template>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            hide-details
            label="E-mail"
            class="required"
            maxlength="100"
            :error="$v.email.$error"
            @blur="$v.email.$touch()"
            v-model="email"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="close" :disabled="loading">
        Cancelar
      </v-btn>
      <v-btn color="primary"
             :loading="loading"
             :disabled="$v.$invalid"
             @click="enviar">
        Enviar
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import LoginService from './service/login-service';

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      email: null,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    enviar() {
      this.loading = true;
      LoginService.solicitarSenha({ email: this.email })
        .then(() => {
          this.$toast('Você receberá um e-mail com as instruções para redefinição da sua senha', { color: 'success' });
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.email) {
        this.email = null;
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
};
</script>
